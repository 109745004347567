.journey {
  padding-left: 0px;
  padding-right: 0px;

  .col-sm-8.col-xs-12,
  .col-sm-4.col-xs-12 {
    padding-left: 0;
    padding-right: 0;
  }

  > div:nth-child(2) > section {
    background-color: $lsaviYellow;

    h4.like-h3 {
      color: $lsaviBlue;
    }

    li {
      margin: 0 0 8px 0;
    }

    li a {
      color: #000;
      font-size: 1.2rem;
    }
  }

  > div > section {
    // background-color: #fff;
    padding: 8px;
    background-color: $lsaviBlue;
    font-family: $robotoFontStack;

    h3,
    h4.like-h3 {
      margin: 0 0 16px 0;
      font-family: $robotoCondensedFontStack;
      font-size: 2.8rem;
      line-height: 3.2rem;
      // color: $lsaviRed;
      color: $lsaviYellow;

      button {
        background-color: transparent;
        margin: 0;
        font-family: $robotoFontStack;
        text-transform: none;
        border: none;
        font-size: 0.9rem;
        line-height: 3.2rem;
        color: #fff;
        display: inline-block;
        letter-spacing: normal;
        height: 3.2rem;
        text-decoration: underline;
        padding: 0 8px;

        &:hover {
          background-color: transparent;
          color: #000;
        }
      }
    }

    p {
      font-family: $robotoCondensedFontStack;
      font-size: 1.2rem;
      line-height: 1.8rem;
      margin: 0 0 16px 0;
    }

    button {
      display: block;
      margin: 8px auto;
      font-family: 'Roboto Condensed', sans-serif;
      text-transform: uppercase;
      background-color: $tillrOrange;
      height: auto;
      padding: 32px;
      border-radius: 5px;
      letter-spacing: 1px;
      line-height: 1.6rem;
      color: #fff;
      font-weight: normal;
      border: none;
      font-size: 1.6rem;

      .material-icons {
        position: relative;
        top: 3px;
      }

      &:hover {
        background-color: $tillrOrange;
        text-decoration: underline;
      }

      &:disabled:hover {
        text-decoration: none;
      }

      &.normal {
        font-size: 0.8rem;
        padding: 0 0.5rem;
        margin: 0;
      }
    }

    .svg-item {
      display: flex;
      justify-content: center;
    }

    ul,
    li {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    li.checklist {
      margin: 0 0 16px;
      position: relative;

      i {
        line-height: 1.6rem;
        position: absolute;
        top: -2px;
        left: 0;
        font-size: 1.6rem;

        &.glyphicon-unchecked {
          color: $tillrOrange;
        }
        &.glyphicon-check {
          color: $completeColor;
        }
      }

      a {
        color: #000;
        font-size: 1.2rem;
        text-decoration: none;
        line-height: 1.6rem;
        position: relative;
        display: block;
        padding-left: 28px;
        text-decoration: underline;

        &:hover {
          color: $lsaviBlue;
        }
      }
    }

    .dashboard-rating {
      margin-bottom: 16px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;

      $starWidth: 45;
      $starHeight: 53;

      span {
        display: block;
        width: #{$starWidth}px;
        height: #{$starHeight}px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        i {
          position: absolute;
          left: 0;
          top: 0;
          font-size: 3.2rem;
          line-height: 3.8rem;
        }

        i:first-child {
          z-index: 1;
        }

        i:last-child {
          z-index: 2;
        }

        &.star-full {
          i:first-child {
            color: transparent;
          }

          i:last-child {
            color: gold;
          }
        }

        &.star-half {
          i:last-child {
            color: gold;
            clip: rect(0, #{$starWidth / 2}px, #{$starHeight}px, 0);
          }
        }
      }

      em {
        color: $completeColor;
        font-size: 2.2rem;
        font-weight: bold;
        line-height: 3.8rem;
        font-style: normal;
        font-family: $robotoFontStack;
      }
    }

    .dashboard-list {
      button {
        width: 100%;
        padding: 16px 32px;

        &:disabled {
          opacity: 0.4;
        }
      }
    }

    .success {
      .dashboard-list {
        button {
          background-color: $completeColor;
        }
      }
    }
  }
}

// @media (max-width: 360px) {

//     .dashboard {

//         section {

//             li {

//                 button {
//                     min-width: 280px;
//                     font-size: 1rem;
//                 }

//             }

//         }

//     }

// }

@media (min-width: 440px) {
  .journey {
    > div > section {
      margin: 0 auto 16px auto;
      // margin-bottom: 16px;
    }
  }
}

@media (min-width: 580px) {
  .journey {
    padding-left: 20px;
    padding-right: 20px;

    > div > section {
      margin: 16px auto;
      border-radius: 50px 0 50px 0;
      padding: 24px 32px 24px 32px;
      margin-bottom: 32px;
      // box-shadow: $boxShadow;
    }

    > div:nth-child(2) > section {
      border-radius: 0 0 50px 0;
    }
  }
}

@media (min-width: 768px) {
  .journey {
    max-width: 1280px;
    // padding: 0 40px;
    margin: 0 auto;
    width: 100%;

    > div > section {
      margin: 16px auto 32px auto;
    }
  }
}

@media (min-width: 780px) {
  .journey {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media (min-width: 1286px) {
  .journey {
    padding-left: 0px;
    padding-right: 0px;
  }
}

/* Timeline Styling */

.wrapper {
  max-width: 1080px;
  margin: 50px auto;
  padding: 0 20px;
  position: relative;
}
.wrapper .center-line {
  position: absolute;
  height: 100%;
  width: 4px;
  background: #fff;
  left: 50%;
  top: 20px;
  transform: translateX(-50%);
}
.wrapper .steps {
  display: flex;
  margin-bottom: 16px;
}
.wrapper .step-1 {
  justify-content: flex-start;
}
.wrapper .step-2 {
  justify-content: flex-end;
}
.wrapper .step-full {
  justify-content: center;
}
.wrapper .steps.disabled section {
  background-color: #ccc;
}

.wrapper .steps.disabled section div.details,
.wrapper .steps.disabled section button,
.wrapper .steps.disabled section p,
// .wrapper .steps.disabled section li,
.wrapper .steps.disabled section div.foot {
  opacity: 0.4;
}

.wrapper .steps.disabled section i,
.wrapper .steps.disabled section i span {
  opacity: 1;
}

.wrapper .steps section {
  background: rgba(255, 255, 255, 1);
  border-radius: 5px;
  width: calc(50% - 40px);
  padding: 20px;
  position: relative;
}
.wrapper .step-full section {
  width: 80%;
}
.wrapper .steps section::before {
  position: absolute;
  content: '';
  height: 15px;
  width: 15px;
  // background: #fff;
  top: 28px;
  z-index: -1;
  transform: rotate(45deg);
}
.step-1 section::before,
.step-full section::before {
  right: -7px;
}
.step-2 section::before {
  left: -7px;
}
.steps section .icon,
.center-line .scroll-icon {
  position: absolute;
  background: #f2f2f2;
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  color: #3ea0e2;
  font-size: 17px;
  box-shadow: 0 0 0 4px #fff, inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05);
}
.center-line .scroll-icon i {
  margin-top: 7px;
}
.steps section .icon span {
  margin-top: 7px;
}
.steps section .icon svg {
  margin-top: -20px;
  margin-left: -17px;
}
.steps section .icon i {
  margin-top: 7px;
  font-size: 2.6rem;
  font-weight: bold;
  font-family: $robotoCondensedBoldFontStack;
  line-height: 40px;
  font-style: normal;
  color: $lsaviRed;
}
.steps section .pending .icon i {
  color: $tillrOrange;
}
.center-line .scroll-icon {
  bottom: 0px;
  left: 50%;
  font-size: 25px;
  transform: translateX(-50%);
}
.step-1 section .icon {
  top: 15px;
  right: -60px;
}
.step-full section .icon {
  display: none;
}
.step-2 section .icon {
  top: 15px;
  left: -60px;
}
.steps section .details,
.steps section .bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.steps section .details .title {
  font-size: 22px;
  font-weight: 600;
}
.steps section p {
  margin: 10px 0 17px 0;
}
.steps section .bottom a {
  text-decoration: none;
  background: #3ea0e2;
  color: #fff;
  padding: 7px 15px;
  border-radius: 5px;
  /* font-size: 17px; */
  font-weight: 400;
  transition: all 0.3s ease;
}
.steps section .bottom a:hover {
  transform: scale(0.97);
}
.steps .foot {
  width: 100%;
  height: 10px;
  background-color: $lsaviRed;
  margin: 16px 0 0;
}
.steps h4 {
  font-family: $robotoCondensedBoldFontStack;
  margin: 0 0 16px;
  font-size: 2rem;
  // color: $lsaviBlue;
}
.steps .success .foot {
  background-color: $completeColor;
}
.steps .pending .foot {
  background-color: $tillrOrange;
}
@media (max-width: 940px) {
  .wrapper .center-line {
    left: 40px;
  }
  .wrapper .steps {
    margin: 30px 0 3px 60px;
  }
  .wrapper .steps section {
    width: 100%;
  }
  .step-1 section::before,
  .step-full section::before {
    left: -7px;
  }
  .step-1 section .icon {
    left: -60px;
  }
}
@media (max-width: 440px) {
  .wrapper .center-line,
  .steps section::before,
  .steps section .icon {
    display: none;
  }
  .wrapper .steps {
    margin: 10px 0;
  }
}

.animated-check {
  height: 5em;
  width: 5em;
}

.animated-check path {
  fill: none;
  stroke: $completeColor;
  stroke-width: 4;
  stroke-dasharray: 23;
  stroke-dashoffset: 23;
  animation: draw 1s linear forwards;
  stroke-linecap: round;
  stroke-linejoin: round;
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}

