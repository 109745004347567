div {
  section {
    .offline-message {
      text-align: center;
      max-width: 220px;
      margin: 1.4rem auto;

      .offline-message__icon {
        font-size: 6rem;
        line-height: 1;
        display: block;
        margin: 0 auto;
        color: #ededed;
      }

      h3 {
        font-size: 1.4rem;
        color: #c9c9c9;
        font-weight: normal;
        margin: 0 0 0.5rem;
        letter-spacing: 0.5;
        line-height: 1.6rem;
      }

      p {
        color: #c9c9c9;
        font-size: 1rem;
        line-height: 1.4rem;
      }

      button {
        font-size: 0.8rem;
        color: #c9c9c9;
        padding: 0 20px 0 16px;
      }
    }
  }
}

@media screen and (min-width: 568px) {
  .offline-message {
    .offline-message__icon {
      font-size: 8rem;
    }
  }
}

@media screen and (min-width: 680px) {
  .offline-message {
    .offline-message__icon {
      font-size: 10rem;
    }
  }
}

@media screen and (min-width: 1025px) {
  .offline-message {
    .offline-message__icon {
      font-size: 12rem;
    }

    h3 {
      font-size: 1.8rem;
      line-height: 2rem;
    }
  }
}

.status-online {
  .offline-message {
    display: none !important;
  }
  &.status-offline-obtrusive-message {
    .offline-message {
      display: none !important;
    }
  }
  &.status-offline-unobtrusive-message {
    .offline-message {
      display: none !important;
    }
  }
}

.status-offline {
  .offline-message {
    display: block !important;
  }

  &.status-offline-obtrusive-message {
    .offline-message {
      display: block !important;
    }
  }
  &.status-offline-unobtrusive-message {
    .offline-message {
      display: block !important;
    }
  }

  .paper {
    display: none !important;
  }
  .banner {
    display: none !important;
  }
  .tillr-list {
    display: none !important;
  }
  .section-report {
    display: none !important;
  }

  .offline-full-support {
    .offline-message {
      display: none !important;
    }

    .paper {
      display: block !important;
    }
    .banner {
      display: block !important;
    }
    .tillr-list {
      display: block !important;
    }
    .section-report {
      display: block !important;
    }
  }

  &.status-offline-obtrusive-message {
    .offline-full-support {
      .offline-message {
        display: none !important;
      }
    }
  }
  &.status-offline-unobtrusive-message {
    .offline-full-support {
      .offline-message {
        display: none !important;
      }
    }
  }
}

// .status-offline {
// 	.status-offline-obtrusive-message {
// 		display: none !important;
// 	}
// 	.status-online-unobtrusive-message {
// 		display: none !important;
// 	}
// }

/*
.offline-message__icon-container {
  text-align: center;
}

.offline-message__icon {
  font-size: 10rem;
  line-height: 1;
  display: inline-block;
  margin: 2rem auto 0 0;
  text-shadow: 0 2px 2px #fff;
  color: #ededed;
}
*/

/*
.offline-message {
	//background-color: #fff;
    //box-shadow: $mdl-box-shadow;
	margin-bottom: 8px;
	display: flex;
	flex-direction: column;
	border: solid 1px #e5e5e5;
	margin-top: 0;
	display: none;
	position: relative;
	z-index: 2;

	.offline-message__primary-content {
		line-height: 20px;
		display: block;
		flex-grow: 2;
		line-height: 20px;
		font-size: 16px;
		font-weight: 400;
		letter-spacing: .04em;
		padding: 16px;

		.offline-message__icon {
			float: left;
			margin-right: 16px;
			height: 40px;
			width: 40px;
			box-sizing: border-box;
			//border-radius: 50%;
			//background-color: #757575;
			font-size: 40px;
			color: $headerBackgroundColor;			text-shadow: 0px 1px 0px rgba(255,255,255,.5);
		}

		.offline-message__primary-content-body {
			margin: 0;
			width: calc(100% - 56px);
			margin-left: 56px;
			font-size: 14px;
			font-weight: 400;
			line-height: 18px;
			display: block;
			letter-spacing: 0;
		}
		
	}

	.offline-message__actions-item {
		cursor: pointer;
		border-top: solid 1px #e5e5e5;
		padding: 8px 16px;
		display: block;
		color: #797979;
		align-items: center;
		display: flex;

		i {
			font-size: 20px;
			line-height: 18px;
			margin-right: 16px;
			font-weight: 400;
		}

		span {
			display: inline-block;
			font-size: 14px;
			line-height: 18px;
		}

		&:hover {
			background-color: #e5e5e5;
			color: $mainTextColor;
		}
	}

}

.connection-status-offline {
	.offline-message {
		display: block;
	}
}

.connection-status-online {
	.offline-message {
		display: none;
	}
}

.page-section-home {
	.offline-message {
		display: none;
	}
}

.connection-status-offline {
	.page-section-home {
		.offline-message {
			display: none;
		}
	}
}

.connection-status-online {
	.page-section-home {
		.offline-message {
			display: none;
		}
	}
}

@media screen and (min-width: 620px) {
    .offline-message {

        margin-top: 76px;
    
    }
}

@media screen and (min-width: 680px) {
    .offline-message {

        margin-top: 92px;
    
    }
}

@media screen and (min-width: 1025px) {
    .offline-message {

        margin-top: 76px;
    
    }
}

*/
