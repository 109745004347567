.textbox {
  padding: 16px;
  margin: 0 0 16px 0;
  border-style: solid;
  border-width: 1px;
  border-color: #cbd2d0;
  color: $mainTextColor;

  &.textbox--blue {
    background-color: #e6ecf7;
    border-color: #b2c1de;
    color: #1f5975;
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0;
    color: $lsaviBlue;
  }
}
