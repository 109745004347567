html,
body {
  height: 100%;
  font-size: 14px;
  line-height: 20px;
  position: relative;
}

body {
  padding-top: 0;
  font-family: $robotoFontStack;
  //background-color: $lsaviPale;
  overflow-y: scroll;
}

.alert ul.margin-bottom {
  margin: 0 0 16px 0;
}

app,
.content {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: center;
}

.no-wrap {
  white-space: nowrap;
}

main {
  flex: 1 0 auto;
  padding-top: 0;
  // display: flex;
  // justify-content: center;
  background-color: #f1f5f7;
  padding-bottom: 32px;
}

.mdl-spinner {
  display: block;
  margin: 16px auto;

  &.mdl-spinner--single-color {
    .mdl-spinner__layer-1 {
      border-color: $primaryColor;
    }
    .mdl-spinner__layer-2 {
      border-color: $lsaviRed;
    }
    .mdl-spinner__layer-3 {
      border-color: $lsaviBlue;
    }
    .mdl-spinner__layer-4 {
      border-color: $lsaviYellow;
    }
  }
}

::-moz-selection {
  background-color: $primaryColor;
  color: #fff;
}

::selection {
  background-color: $primaryColor;
  color: #fff;
}

a {
  color: $primaryColor;
}

.pointer {
  cursor: pointer;
}

.responsive-table {
  .responsive-table__row {
    width: 100%;
    //border-bottom: solid 1px #cbd2d0;
    padding: 8px 0;
    &.responsive-table__head {
      display: none;
    }
    .responsive-table__row-inner {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      &.responsive-table__row-inner__single-line {
        align-items: center;
        .responsive-table__col {
          padding-bottom: 0;
        }
      }
      > div {
        width: 100%;
      }
      .responsive-table__col {
        flex-grow: 1;
        padding: 0 0 8px;
        &.responsive-table__col-head {
          font-weight: bold;
        }
      }
      .form-group__input {
        width: 100%;
        padding: 0 0 8px;
      }
    }
  }
}

.mdl-list.mdl-file-list {
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 16px;
  background-color: $lsaviLightGrey;
  &:last-child {
    border-bottom: none;
  }

  .mdl-list__item {
    border-bottom: solid 1px #cbd2d0;
    outline: none;
    &:hover {
      background-color: #cbd2d0;

      .mdl-list__item-secondary-content {
        cursor: pointer;
      }

      .mdl-tooltip {
        span {
          color: #fff;
        }
      }
    }
    &:active {
      background-color: $lsaviBlue;
      span {
        color: #fff;
      }
    }
    .mdl-list__item-avatar {
      text-align: center;
      i {
        position: relative;
        left: 2px;
        top: -7px;
        font-size: 26px;
        line-height: 40px;
      }
    }
  }
}

.visible-print {
  display: none !important;

  @media print {
    display: block !important;
  }
}

.contact-button {
  position: fixed;
  bottom: 16px;
  right: 16px;
  z-index: 10002;
  background-color: $lsaviRed;
  color: #fff;
  box-shadow: $mdl-box-shadow-4;

  &:focus,
  &:active {
    background-color: $lsaviRed;
    color: #fff;
  }

  &:hover {
    background-color: $primaryColor;
    color: #fff;
  }
}

/* progress overrides */
// .progress-indicator {
// 	li {
// 		a {
// 			text-decoration: none;
// 			outline: none;
// 		}

// 		&.warning,
// 		&.warning.active {
// 			a {
// 				color: #edb10a;
// 				&:hover {
// 					color: #dd1c1a;
// 				}
// 			}
// 		}

// 		&.active {
// 			a {
// 				color: #00A0E4;
// 				&:hover {
// 					color: #dd1c1a;
// 				}
// 			}
// 		}

// 		&.completed {
// 			a {
// 				color: #65d074 !important;
// 				&:hover {
// 					color: #dd1c1a;
// 				}
// 			}
// 		}

// 		a {
// 			color: #999;
// 			text-decoration: none;
// 			&:hover {
// 				color: #dd1c1a;
// 			}

// 			strong {
// 				display: block;
// 				font-weight: normal;
// 				font-size: 0.9rem;
// 				line-height: 1.1rem;
// 				&.underline {
// 					text-decoration: underline;
// 				}
// 			}

// 			em {
// 				font-style: normal;
// 				display: block;
// 				font-size: 1.2rem;
// 				line-height: 1.2rem;
// 			}
// 		}

// 		button {
// 			color: #fff;
// 			background-color: rgba(0, 160, 228, 1);

// 			&:hover {
// 				background-color: rgba(0, 160, 228, 0.5);
// 			}
// 		}

// 	}
// }

// .progress-indicator.custom-complex {
// 	background-color: #f1f1f1;
// 	padding: 10px 5px;
// 	border: 1px solid #ddd;
// 	border-radius: 10px;
// }
// .progress-indicator.custom-complex > li .bubble {
// 	height: 12px;
// 	width: 99%;
// 	border-radius: 2px;
// 	box-shadow: inset -5px 0 12px rgba(0, 0, 0, 0.2);
// }
// .progress-indicator.custom-complex > li .bubble:before,
// .progress-indicator.custom-complex > li .bubble:after {
// 	display: none;
// }

// /* Demo for vertical bars */

// .progress-indicator.stepped.stacked {
// 	width: 48%;
// 	display: inline-block;
// }
// .progress-indicator.stepped.stacked > li {
// 	height: 150px;
// }
// .progress-indicator.stepped.stacked > li .bubble {
// 	padding: 0.1em;
// }
// .progress-indicator.stepped.stacked > li:first-of-type .bubble {
// 	padding: 0.5em;
// }
// .progress-indicator.stepped.stacked > li:last-of-type .bubble {
// 	padding: 0em;
// }

// /* Nocenter */

// .progress-indicator.nocenter.stacked > li {
// 	min-height: 100px;
// }
// .progress-indicator.nocenter.stacked > li span {
// 	display: block;
// }

.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
}

