.svg-item {
  width: 100%;
  font-size: 16px;
  margin: 0 auto;
  animation: donutfade 1s;
  max-width: 360px;
}

@keyframes donutfade {
  /* this applies to the whole svg item wrapper */
  0% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

@media (min-width: 992px) {
  .svg-item {
    width: 80%;
  }
}

.donut-ring {
  stroke: #ebebeb;
}

.donut-segment {
  stroke: $tillrOrange;
  animation: donut 3s;
}

.segment {
  fill: $tillrOrange;
}

.donut-percent {
  animation: donutfadelong 2s;
}

@keyframes donutfadelong {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.donut-text {
  font-family: Arial, Helvetica, sans-serif;
  fill: #ff6200;
}

.donut-text {
  fill: $tillrOrange;
}

.donut-label {
  font-size: 0.28em;
  font-weight: 700;
  line-height: 1;
  fill: #000;
  transform: translateY(0.25em);
}

.donut-percent {
  font-size: 0.5em;
  line-height: 1;
  transform: translateY(0.5em);
  font-weight: bold;
}

.donut-data {
  font-size: 0.18em;
  line-height: 1;
  transform: translateY(0.5em);
  text-align: center;
  text-anchor: middle;
  color: #666;
  fill: #666;
  animation: donutfadelong 1s;
}

/* ---------- */
/* just for this presentation */
.svg-item {
  //max-width:30%;
  display: inline-block;
}
