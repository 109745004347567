.page {
  width: 100%;
  max-width: 1280px;
  margin: 16px auto 32px;

  section {
    background-color: #fff;
    padding: 8px;
    box-shadow: $boxShadow;

    h3 {
      margin: 0 0 16px 0;
      font-family: $robotoCondensedBoldFontStack;
      font-size: 2.4rem;
      line-height: 2.8rem;
      color: $lsaviRed;

      button {
        background-color: transparent;
        margin: 0;
        font-family: $robotoFontStack;
        text-transform: none;
        border: none;
        font-size: 0.9rem;
        line-height: 2.8rem;
        color: #666;
        display: inline-block;
        letter-spacing: normal;
        height: 2.8rem;
        text-decoration: underline;
        padding: 0 8px;

        &:hover {
          background-color: transparent;
          color: $lsaviRed;
        }
      }
    }

    table {
      width: 100%;
    }

    p {
      font-family: $robotoCondensedFontStack;
      font-size: 1.2rem;
      line-height: 1.8rem;
    }

    li {
      list-style: none;
      margin: 0 0 8px 0;
    }

    .form__main-buttons {
      padding: 0 15px;
    }
  }
}

@media (min-width: 580px) {
  .page {
    section {
      border-radius: 50px 0 50px 0;
      padding: 24px 32px 24px 32px;
    }
  }
}

