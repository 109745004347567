// .collapsable-groups__group h2 {
//   font-size: 1.8rem;
//   font-family: $robotoFontStack;
//   border: none;
//   padding-bottom: 0;
//   line-height: 2.2rem;
//   color: $mainTextColor;
//   margin-top: 0;
// }

/* form group wrapper for collapsable groupd sections */
// .collapsable {
// 	.collapsable-groups__head {
// 		display: none;
// 	}
// }

.collapsable {
  .collapsable-groups__head {
    display: none;
  }
  &.collapsable-groups {
    .collapsable-groups__group {
      h2 {
        font-size: 1.8rem;
        font-family: $robotoFontStack;
        border: none;
        padding-bottom: 0;
        line-height: 2.2rem;
        color: $mainTextColor;
        margin-top: 0;
      }
      clear: both;
      margin: 0 0 16px 0;
      .collapsable-groups__head {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-content: flex-start;
        background-color: #e6e6e6;
        align-items: center;
        color: $mainTextColor;

        &.stars0 {
          //background-color: $noStars;
          background-color: $primaryColor;
          h3 {
            color: #fff;
          }
        }
        &.stars1 {
          //background-color: $oneStar;
          //color: #000;
          background-color: $primaryColor;
          h3 {
            color: #fff;
          }
        }
        &.stars2 {
          //background-color: $twoStars;
          background-color: $primaryColor;
          h3 {
            color: #fff;
          }
        }
        &.stars3 {
          //background-color: $threeStars;
          background-color: $primaryColor;
          h3 {
            color: #fff;
          }
        }
        &.stars4 {
          //background-color: $fourStars;
          background-color: $primaryColor;
          h3 {
            color: #fff;
          }
        }
        &.stars5 {
          //background-color: $fiveStars;
          background-color: $primaryColor;
          h3 {
            color: #fff;
          }
        }

        button {
          border: none;
          background-color: #000;
          color: #fff;

          &.print {
            background-color: $lsaviBlue;
            &:hover {
              text-decoration: underline;
            }
          }

          &:hover {
            background-color: $lsaviBlue !important;
          }

          &:disabled {
            opacity: 0.5;
          }
        }

        &.collapsable-groups__head--begun {
          background-color: #edb10a;
        }

        &.collapsable-groups__head--complete {
          background-color: #65d074;
          color: #fff;

          h3 {
            color: #fff;
          }
        }

        .collapsable-groups__head-title {
          flex-basis: 70%;
          padding: 8px;

          .text {
            display: inline-block;
            min-width: 138px;
            &.print-friendly {
              display: none;
            }
          }

          .stars {
            white-space: nowrap;
            i.glyphicon {
              line-height: 1.4rem;
              color: #000;
              &.star-on {
                color: #ffff00;
              }
            }
          }
        }
        .collapsable-groups__head-status {
          flex-basis: 20%;
          padding: 8px;
          text-align: center;
        }
        .collapsable-groups__head-toggle {
          flex-basis: 10%;
          padding: 8px;
          text-align: right;
        }

        p {
          margin: 0;
          font-size: 1rem;
          line-height: 1.4rem;
          font-weight: bold;
          font-family: $defaultFontStack;
        }

        h3 {
          margin: 0;
          font-size: 1rem;
          line-height: 1.4rem;
          font-family: $defaultFontStack;
          color: $mainTextColor;
          font-weight: bold;
        }
      }
      // .collapsable-groups__head {
      //     display: block;
      //     background-color: #e6e6e6;
      //     position: relative;
      //     button {
      //         padding: 16px 64px 16px 16px;
      //         font-size: 1.2rem;
      //         background-color: transparent;
      //         display: block;
      //         //width: 100%;
      //         text-align: left;
      //         border: none;

      // overflow: hidden;
      // display: block;
      // white-space: nowrap;
      // text-overflow: ellipsis;

      //         i {
      //             position: absolute;
      //             right: 16px;
      //             top: 20px;
      //         }
      //     }
      // }
      .collapsable-groups__body {
        overflow: auto;
        border-style: solid;
        border-color: #e6e6e6;
        border-width: 0 1px 1px 1px;
        padding: 16px;
        display: none;

        h5 {
          margin: 0 0 16px 0;
          font-size: 1.1rem;
          line-height: 1.5rem;
          font-weight: bold;
          letter-spacing: 0;
        }

        h5 span,
        p span {
          color: $lsaviBlue;
          font-weight: bold;
        }

        p {
          font-weight: bold;
        }

        p i {
          display: block;
          font-style: normal;
        }

        p.max-score {
          background-color: #00b050;
          padding: 2px 4px;
          color: #fff;
          border-radius: 2px;
          display: inline-block;
        }

        .recommendations-checkboxes {
          h6 {
            margin: 0;
            font-size: 1rem;
            line-height: 1.4rem;
            font-weight: bold;
            font-family: $robotoFontStack;
            color: $lsaviBlue;
            letter-spacing: 0;
          }

          ul {
            margin: 0 0 16px 16px;

            li {
              font-weight: bold;
            }
          }
        }

        .recommendation {
          background-color: $lsaviBlue;
          padding: 8px;
          border-radius: 4px;

          max-height: 120px;
          position: relative;
          overflow: hidden;
          color: #fff;

          > p {
            margin: 0;
          }

          p {
            font-weight: normal;
            white-space: pre-wrap;

            a {
              color: $lsaviYellow;
            }
          }

          p p:last-child {
            margin: 0;
          }

          .read-more,
          .read-less {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            text-align: center;
            margin: 0;
            padding: 0 0 16px;

            /* "transparent" only works here because == rgba(0,0,0,0) */
            background-image: linear-gradient(to bottom, transparent, $lsaviBlue);

            a {
              display: inline-block;
              background-color: $primaryColor;
              color: #fff;
              text-decoration: none;
              padding: 4px 8px;
              text-transform: uppercase;
              &:hover {
                background-color: $lsaviRed;
              }
            }
          }

          .read-less {
            display: none;
          }

          &.recommendation-visible {
            max-height: none;
            padding-bottom: 42px;
            .read-more {
              display: none;
            }
            .read-less {
              display: block;
            }
          }
        }
      }
      &.collapsable-groups__group--visible {
        .collapsable-groups__body {
          display: block;
        }
      }
    }
  }
}

