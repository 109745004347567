.rellax {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  //z-index: -1;

  img {
    position: absolute;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
  }
}
