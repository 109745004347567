// IE 11 fix
.header {
  overflow: auto;
}

header {
  .signed-in-as {
    padding: 10px;
    background-color: #00a0ef;
    display: block;

    &__inner {
      max-width: 1280px;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      align-items: center;

      p {
        margin: 0;
        flex-grow: 1;
        color: #fff;

        a {
          color: #fff;

          &:hover {
            color: rgba(255, 255, 255, 0.6);
          }
        }
      }

      ul,
      li {
        margin-top: 0;
        margin-bottom: 0;
        padding: 0;
        list-style: none;
      }

      ul.socials {
        // This is enabled in larger screens
        display: none;
        flex-grow: 1;
        flex-direction: row;
        justify-content: flex-end;

        li {
          padding-left: 15px;

          a {
            svg {
              fill: rgba(255, 255, 255, 0.6);
            }

            &:hover {
              svg {
                fill: #fff;
              }
            }
          }
        }
      }
    }
  }

  .main {
    min-width: 262px;
    width: 100%;
    position: relative;
    z-index: 2;
    background-color: #000;
    align-items: center;

    > div {
      max-width: 1280px;
    }

    h1 {
      margin: 0;

      a {
        display: block;
        img {
          width: 100%;
          max-width: 260px;
        }
      }
    }

    h6 {
      display: none;
    }
  }
}

@media (min-width: 500px) {
  header {
    .main {
      background-color: transparent;

      h1 {
        a {
          img {
            max-width: 314px;
            margin: 0;
          }
        }
      }
    }

    // main {
    //     padding-top: 20px;
    // }
  }
}

@media (min-width: 540px) {
  header {
    .main {
      > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0 auto;

        h1 {
          flex-grow: 1;
          a {
            img {
              width: 300px;
            }
          }
        }

        h6 {
          flex-grow: 1;
          display: none;
          text-align: right;
          margin: 0;
          flex-grow: 1;
          display: inline-block;
          white-space: nowrap;
          a {
            text-decoration: none;

            &:hover {
              text-decoration: none;
            }
            span {
              line-height: 40px;
              font-size: 0.9rem;
              color: #666;
              padding-right: 8px;
            }

            img {
              height: 40px;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 580px) {
  header {
    .signed-in-as {
      padding-left: 20px;
      padding-right: 20px;
    }

    .main {
      padding: 0 20px 0 20px;
      margin: 0 auto;
      border-bottom: solid 1px #e0e5ed;
    }
  }
}

@media (min-width: 620px) {
  header {
    .signed-in-as {
      ul.socials {
        display: flex;
      }
    }

    .main {
      padding: 0 20px 0 20px;
    }
  }
}

@media (min-width: 780px) {
  header {
    .signed-in-as {
      padding-left: 40px;
      padding-right: 40px;
    }
    .main {
      padding: 0 40px 0 40px;
    }
  }
}

