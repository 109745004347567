.mdl-mega-footer {
  //position: relative;
  //z-index: 1;
  padding: 16px;
  width: 100%;
  height: 16rem;
  background-color: #0c2434;
  color: rgba(255, 255, 255, 0.7);

  p {
    font-size: 1rem;
    margin: 0;
    line-height: 36px;
  }

  button {
    text-decoration: none;
    color: #9e9e9e;
    font-size: 1rem;
    &:hover {
      color: $lsaviRed;
    }
  }
}

.mdl-mega-footer--top-section {
  .mdl-mega-footer--left-section {
    a {
      text-decoration: none;
      strong {
        color: $tillrOrange;
        font-weight: normal;
      }
    }

    .mdl-mega-footer--top-section {
      max-width: 1280px;
      margin: 0 auto;
      .mdl-mega-footer--left-section {
        a {
          text-decoration: none;
          strong {
            color: $tillrOrange;
            font-weight: normal;
          }

          &:hover {
            color: $tillrOrange;
            text-decoration: underline;
          }
        }
      }
    }
  }
}

.back-to-top {
  cursor: pointer;
}

@media (min-width: 720px) {
  .mdl-mega-footer {
    padding: 32px;
  }
}

