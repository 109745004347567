.dashboard {
  section {
    background-color: #fff;
    padding: 8px;
    box-shadow: $boxShadow;

    h3 {
      margin: 0 0 16px 0;
      font-family: $robotoCondensedBoldFontStack;
      font-size: 2.4rem;
      line-height: 2.8rem;
      color: $lsaviRed;

      button {
        background-color: transparent;
        margin: 0;
        font-family: $robotoFontStack;
        text-transform: none;
        border: none;
        font-size: 0.9rem;
        line-height: 2.8rem;
        color: #666;
        display: inline-block;
        letter-spacing: normal;
        height: 2.8rem;
        text-decoration: underline;
        padding: 0 8px;

        &:hover {
          background-color: transparent;
          color: #000;
        }
      }
    }

    table {
      width: 100%;
    }

    p {
      font-family: $robotoCondensedFontStack;
      font-size: 1.2rem;
      line-height: 1.8rem;
      margin: 0 0 16px 0;

      .dashboard-score {
        color: $primaryColor;
        font-size: 1.8rem;
        line-height: 1.8rem;
      }
    }

    button {
      display: block;
      margin: 8px auto;
      font-family: 'Roboto Condensed', sans-serif;
      text-transform: uppercase;
      background-color: $primaryColor;
      height: auto;
      padding: 32px;
      border-radius: 5px;
      letter-spacing: 1px;
      line-height: 1.6rem;
      color: #fff;
      font-weight: normal;
      border: none;
      font-size: 1.6rem;

      .material-icons {
        position: relative;
        top: 3px;
      }

      &:hover {
        background-color: $lsaviRed;
      }
    }

    .dashboard-continue {
      text-align: center;
      margin-bottom: 16px;
      display: flex;

      > div:first-child {
        flex-basis: 60%;
      }

      > div:last-child {
        flex-basis: 40%;
      }

      .svg-item {
        margin: 0 auto;
      }
    }

    li {
      list-style: none;
      margin: 0 0 8px 0;

      button {
        display: block;
        font-family: 'Roboto Condensed', sans-serif;
        text-transform: uppercase;
        background-color: $lsaviBlue;
        height: auto;
        padding: 16px;
        border-radius: 5px;
        letter-spacing: 1px;
        line-height: 1.4rem;
        color: #fff;
        font-weight: normal;
        border: none;
        font-size: 1.2rem;
        //min-width: 300px;
        width: 100%;

        &.button-primary {
          background-color: $primaryColor;

          &:hover {
            color: #fff;
            background-color: $lsaviRed;
          }
        }

        &.button-red {
          background-color: $lsaviRed;

          &:hover {
            color: $lsaviRed;
            background-color: $lsaviYellow;
          }
        }

        &[disabled] {
          opacity: 0.3;
          cursor: not-allowed;

          &:hover {
            background-color: $lsaviBlue;
          }
        }
      }
    }

    .dashboard-rating {
      margin-bottom: 16px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;

      $starWidth: 45;
      $starHeight: 53;

      span {
        display: block;
        width: #{$starWidth}px;
        height: #{$starHeight}px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        i {
          position: absolute;
          left: 0;
          top: 0;
          font-size: 3.2rem;
          line-height: 3.8rem;
        }

        i:first-child {
          z-index: 1;
        }

        i:last-child {
          z-index: 2;
        }

        &.star-full {
          i:first-child {
            color: transparent;
          }

          i:last-child {
            color: gold;
          }
        }

        &.star-half {
          i:last-child {
            color: gold;
            clip: rect(0, #{$starWidth / 2}px, #{$starHeight}px, 0);
          }
        }
      }

      em {
        color: $primaryColor;
        font-size: 2.2rem;
        font-weight: bold;
        line-height: 3.8rem;
        font-style: normal;
        font-family: $robotoFontStack;
      }
    }

    .dashboard-rating-stickers {
      margin-bottom: 8px;
      background-color: $lsaviYellow;
      padding: 8px;

      h3 {
        font-size: 2rem;
        line-height: 2.4rem;
        margin: 0 0 8px 0;
        font-family: $robotoCondensedBoldFontStack;
        position: relative;
        padding-left: 34px;
        color: $lsaviRed;

        i {
          position: absolute;
          top: 6px;
          left: 0;
        }
      }

      a {
        color: $lsaviRed;

        &:hover {
          color: $lsaviBlue;
        }
      }
    }
  }
}

// @media (max-width: 360px) {

//     .dashboard {

//         section {

//             li {

//                 button {
//                     min-width: 280px;
//                     font-size: 1rem;
//                 }

//             }

//         }

//     }

// }

@media (min-width: 440px) {
  .dashboard {
    width: 100%;

    section {
      margin: 20px 8px 16px 8px;

      .dashboard-rating {
        $starWidth: 70;
        $starHeight: 78;

        span {
          width: #{$starWidth}px;
          height: #{$starHeight}px;

          i {
            font-size: 5rem;
            line-height: 5.6rem;
          }

          &.star-half {
            i:last-child {
              clip: rect(0, #{$starWidth / 2}px, #{$starHeight}px, 0);
            }
          }
        }

        em {
          font-size: 3.2rem;
          line-height: 5.6rem;
        }
      }
    }

    li {
      button {
        margin: 0;
      }
    }
  }
}

@media (min-width: 580px) {
  .dashboard {
    section {
      margin: 16px;
      border-radius: 50px 0 50px 0;
      padding: 24px 32px 24px 32px;
      margin-bottom: 32px;

      .dashboard-rating-stickers {
        border-radius: 4px;
        margin-bottom: 16px;
        padding: 16px;

        h3 {
          margin: 0 0 8px 0;
          font-family: $robotoCondensedBoldFontStack;
        }
      }
    }
  }
}

@media (min-width: 710px) {
  .dashboard-continue {
    display: flex;
    align-items: center;

    .svg-item {
      margin: 0;
      flex-basis: 40%;
    }

    > div {
      flex-basis: 60%;
      button {
        margin: 0;
      }
    }
  }
}

@media (min-width: 768px) {
  .dashboard {
    section {
      margin-left: 16px;
      margin-bottom: 32px;
      margin-right: 16px;
      // max-width: 820px;
    }

    .dashboard-continue {
      .svg-item {
        flex-basis: 50%;
      }

      > div {
        flex-basis: 50%;
      }
    }
  }
}

@media (min-width: 890px) {
  .dashboard {
    section {
      margin-left: 10%;
    }
  }
}

