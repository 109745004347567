.lsavi-postcode-lookup {
  .lsavi-postcode-lookup__search {
    display: flex;
    flex-direction: row;

    .form-group {
      margin: 0;
    }

    > div {
      flex: 0 0 60%;
      padding-left: 0;
      padding-right: 0;
      margin: 0;
    }

    > div:last-child {
      align-self: flex-end;
      flex: 0 0 40%;

      button {
        white-space: nowrap;
        background-color: $lsaviRed;
        color: #fff;
        margin: 0 0 0 8px;
        max-height: 34px;
      }
    }
  }

  p {
    margin: 8px 0;

    a {
      color: $mainTextColor;
      text-decoration: underline;
    }
  }
}

@media screen and (min-width: 520px) {
  .lsavi-postcode-lookup {
    .lsavi-postcode-lookup__search {
      //width: 70%;
    }
  }
}
