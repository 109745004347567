$headerTableCellBackgroundColor: #4c5b71;
$headerTableCellHoverBackgroundColor: #3e4b5d;
$headerTableCellMovingBackgroundColor: #3e4b5d;
$headerTableCellColor: #fff;
$headerTableCellWeight: 700;

$headerArrowOnColor: #fff;
$headerArrowOffColor: #909fb4;

$standardTableCellBackgroundColorOdd: #fff5ec;
$standardTableCellBackgroundColorEven: #fff;
$standardTableCellHoverBackgroundColor: #ffe2ca;
$standardTableCellHoverColor: #000;

$boldBorderColor: #999;
$standardBorderColor: #e0e0e0;

$controlsHeaderBackgroundColor: $headerTableCellBackgroundColor;
$controlsHeaderColor: $headerTableCellColor;
$controlsFormBorderColor: #999;
$controlsFormBackgroundColor: #4c5b71;
$controlsButtonBackgroundColor: #ccc;
$controlsButtonHoverColor: $headerTableCellBackgroundColor;

$controlsFormColor: #fff;

/* General styles */
#tillr-mdl-modal {
  #reports-logo {
    position: absolute;
    right: 16px;
    top: 16px;
  }

  #reports-close-link {
    position: absolute;
    right: 0;
    top: 0;
    //@warnfont-size: #{$defaultFontSize + 0.2}rem;
    color: #999;
    cursor: pointer;
    display: block;
    padding: 4px;
    text-decoration: none;

    &:hover {
    }
  }

  #report-loader {
    text-align: center;
    margin-top: 16px;

    .mdl-spinner {
      width: 20px;
      height: 20px;
      margin: 12px auto;
      display: inline-block;

      &.mdl-spinner--single-color {
        .mdl-spinner__layer-1 {
          border-color: $primaryColor;
        }
        .mdl-spinner__layer-2 {
          border-color: $primaryColor;
        }
        .mdl-spinner__layer-3 {
          border-color: $primaryColor;
        }
        .mdl-spinner__layer-4 {
          border-color: $primaryColor;
        }
      }
    }

    p {
      color: $mainTextColor;
    }
  }

  #report-error {
    padding: 0 16px;
    color: $mainTextColor;
    box-shadow: none;
    background-color: transparent;
  }

  #report-results-wrapper {
    .report__controls {
      display: inline-block;
      box-shadow: $mdl-box-shadow;
      background-color: $controlsHeaderBackgroundColor;
      margin: 0 16px 16px 0;
      vertical-align: top;

      &:last-of-type {
        margin-right: 0;
      }

      &#report-filters {
        form {
          padding: 8px;
          select {
            margin-bottom: 8px;
          }
          input {
            margin-bottom: 8px;
          }
          button {
            margin-bottom: 0;
          }
        }
      }

      h3 {
        font-size: #{$defaultFontSize - 0.1}rem;
        line-height: 37px;
        padding: 0 8px;
        color: $controlsHeaderColor;
        font-weight: $headerTableCellWeight;
        margin: 0;
        letter-spacing: 0.5px;

        display: none;
      }

      form {
        padding: 8px 8px 15px 8px;
        background-color: $controlsFormBackgroundColor;

        label.control-label {
          font-size: #{$defaultFontSize - 0.1}rem;
          margin: 0;
          color: $controlsFormColor;
        }

        input.form-control,
        select.form-control {
          font-size: #{$defaultFontSize - 0.1}rem;
          padding: 8px;
          border: none;
        }

        input.form-control:last-of-type {
          margin-bottom: 8px;
        }

        select.form-control {
          min-width: 120px;
        }

        button {
          font-size: #{$defaultFontSize - 0.2}rem;
          padding: 8px;
          min-width: 0;
          line-height: #{$defaultFontSize}rem;
          height: auto;
          background-color: $controlsButtonBackgroundColor;

          &:hover {
            color: $controlsButtonHoverColor;
          }
        }
      }
    }

    .report__buttons {
      margin-bottom: 16px;
      button {
        background-color: $controlsHeaderBackgroundColor;
        font-size: #{$defaultFontSize - 0.2}rem;
        color: $controlsHeaderColor;
        margin-right: 8px;

        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }

  /* Tabulator specific */
  div.tabulator {
    font-family: $defaultFontStack;
    font-size: #{$defaultFontSize - 0.1}rem !important;
    box-shadow: $mdl-box-shadow;

    .tabulator-header {
      border-color: $boldBorderColor;
      border-width: 1px;
      background-color: $headerTableCellBackgroundColor;

      .tabulator-col {
        min-height: 37px !important;
        background-color: $headerTableCellBackgroundColor;
        color: $headerTableCellColor;
        letter-spacing: 0.5px;
        font-weight: $headerTableCellWeight;
        border-right: 1px solid $standardBorderColor;

        &.tabulator-moving {
          background-color: $headerTableCellMovingBackgroundColor !important;
        }

        &.tabulator-sortable[aria-sort='none'] {
          .tabulator-col-content {
            .tabulator-arrow {
              border-bottom-color: $headerArrowOffColor;
            }
          }
        }

        &.tabulator-sortable[aria-sort='asc'] {
          .tabulator-col-content {
            .tabulator-arrow {
              border-bottom-color: $headerArrowOnColor;
            }
          }
        }

        &.tabulator-sortable[aria-sort='desc'] {
          .tabulator-col-content {
            .tabulator-arrow {
              border-top-color: $headerArrowOnColor;
            }
          }
        }

        &.tabulator-sortable {
          &:hover {
            background-color: $headerTableCellHoverBackgroundColor;
            -webkit-transition: background-color 0.3s ease;
            -o-transition: background-color 0.3s ease;
            transition: background-color 0.3s ease;
          }
        }
      }
    }

    .tabulator-row {
      &.tabulator-row-odd {
        .tabulator-cell {
          background-color: $standardTableCellBackgroundColorOdd;
        }
      }

      &.tabulator-row-even {
        .tabulator-cell {
          background-color: $standardTableCellBackgroundColorEven;
        }
      }

      .tabulator-frozen.tabulator-frozen-left {
        border-color: $boldBorderColor;
        border-width: 1px;

        transition: none;
      }

      .tabulator-cell {
        border-right: 1px solid $standardBorderColor;
      }

      &.tabulator-selectable:hover {
        -webkit-transition: background-color 0.3s ease;
        -o-transition: background-color 0.3s ease;
        transition: background-color 0.3s ease;

        .tabulator-cell {
          background-color: $standardTableCellHoverBackgroundColor !important;
          color: $standardTableCellHoverColor;

          -webkit-transition: background-color 0.3s ease;
          -o-transition: background-color 0.3s ease;
          transition: background-color 0.3s ease;
        }
      }
    }

    .tabulator-header .tabulator-frozen.tabulator-frozen-left {
      border-color: $boldBorderColor;
      border-width: 1px;
    }

    .tabulator-loading {
      border: none;
      border-radius: 0;
      font-size: #{$defaultFontSize - 0.1}rem !important;
      color: $mainTextColor;
      padding: 16px 32px;
      font-weight: normal;
    }

    .tabulator-placeholder {
      margin: 0 auto 16px;
      span {
        font-size: #{$defaultFontSize - 0.1}rem !important;
        color: $mainTextColor;
        padding: 0;
        font-weight: normal;
      }
    }

    .tabulator-error {
      border: none;
      border-radius: 0;
      font-size: #{$defaultFontSize - 0.1}rem !important;
      color: $dangerColor;
      padding: 16px 32px;
      font-weight: normal;
    }
  }

  // D3 Specific
  &.report-type-d3 {
    #report-results-wrapper {
      #report-buttons {
        display: none;
      }

      #report-filters {
        display: none;
      }

      .report__controls {
        form {
          padding: 8px !important;

          label.control-label {
            padding: 0 8px;

            &:first-of-type {
              padding-left: 0;
            }
          }

          input.form-control,
          select.form-control {
            margin-bottom: 0 !important;
            display: inline-block !important;
            width: auto !important;
          }

          input.form-control:last-of-type {
            margin-right: 8px;
            margin-bottom: 0 !important;
          }

          button {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

/* General responsive styles - <body> used here for legacy purposes */
@media screen and (min-width: 767px) {
  #tillr-mdl-modal {
    #report-results-wrapper {
      .report__controls {
        &#report-filters {
          form {
            select,
            input {
              //margin: 0 8px 0 0;
              margin-right: 8px;
              // margin-bottom: 0 !important;
            }
          }
        }

        form {
          padding: 8px !important;

          label.control-label {
            padding: 0 8px;

            &:first-of-type {
              padding-left: 0;
            }
          }

          input.form-control,
          select.form-control {
            margin-bottom: 0 !important;
          }

          input.form-control:last-of-type {
            margin-right: 8px;
            margin-bottom: 0 !important;
          }

          button {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

/* General print styles - <body> used here for legacy purposes */
@media print {
  .mdl-layout__container {
    display: none;
  }

  #tillr-mdl-modal {
    #report {
      height: auto !important;
    }

    #report-results-wrapper {
      .report__controls {
        display: none !important;
      }
    }

    #reports-close-button {
      display: none;
    }
  }
}
