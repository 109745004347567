.contact {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;

  > section {
    box-shadow: $boxShadow;
  }

  section {
    background-color: #fff;
    padding: 8px;

    h3 {
      margin: 0 0 16px 0;
      font-family: $robotoCondensedBoldFontStack;
      font-size: 2.4rem;
      line-height: 2.8rem;
      color: $lsaviRed;

      button {
        background-color: transparent;
        margin: 0;
        font-family: $robotoFontStack;
        text-transform: none;
        border: none;
        font-size: 0.9rem;
        line-height: 2.8rem;
        color: #666;
        display: inline-block;
        letter-spacing: normal;
        height: 2.8rem;
        text-decoration: underline;
        padding: 0 8px;

        &:hover {
          background-color: transparent;
          color: $lsaviRed;
        }
      }
    }

    .form-group {
      margin-bottom: 24px;

      textarea {
        min-height: 100px;
      }
    }

    .form__main-buttons {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      padding: 0 15px;
    }

    .required-fields-info {
      p {
        padding: 0 15px;
        margin: 0 0 16px 0;
      }
    }
  }
}

@media (min-width: 440px) {
  .contact {
    section {
      margin: 8px;
      margin-bottom: 16px;
    }
  }
}

@media (min-width: 580px) {
  .contact {
    section:not(.step) {
      margin: 16px;
      border-radius: 50px 0 50px 0;
      padding: 24px 32px 24px 32px;
      margin-bottom: 32px;
    }

    section {
      .form-paper-status {
        margin: 0;
      }
    }
  }
}

@media (min-width: 768px) {
  .contact {
    section:not(.step) {
      margin-left: 16px;
      margin-bottom: 32px;
      margin-right: 16px;
      max-width: 820px;
    }
  }
}

@media (min-width: 1200px) {
  .contact {
    section:not(.step) {
      margin-left: 10%;
    }
  }
}

