.recommendations {
  > section {
    box-shadow: $boxShadow;
  }

  section {
    background-color: #fff;
    padding: 8px;

    h3 {
      margin: 0 0 16px 0;
      font-family: $robotoCondensedBoldFontStack;
      font-size: 2.4rem;
      line-height: 2.8rem;
      color: $lsaviRed;

      button {
        background-color: transparent;
        margin: 0;
        font-family: $robotoFontStack;
        text-transform: none;
        border: none;
        font-size: 0.9rem;
        line-height: 2.8rem;
        color: #666;
        display: inline-block;
        letter-spacing: normal;
        height: 2.8rem;
        text-decoration: underline;
        padding: 0 8px;

        &:hover {
          background-color: transparent;
          color: #000;
        }
      }
    }

    ol {
      margin: 0 0 16px 16px;
      padding: 0;

      li {
        margin: 0 0 8px 0;
      }
    }

    div.recommendation {
      > p:first-child {
        margin-bottom: 8px !important;
      }
    }

    .go-to-link {
      margin-top: 12px;
      color: #086788;
      display: inline-block;
      font-weight: bold;
      &:hover {
        color: $lsaviRed;
      }

      &.critical-link {
        color: $lsaviRed;
        font-size: 1.2rem;
      }
    }

    .collapsable.collapsable-groups {
      .collapsable-groups__group {
        .collapsable-groups__head {
          .collapsable-groups__head-status {
            text-align: right;
          }
        }
      }
    }
  }
}

@media (min-width: 440px) {
  .recommendations {
    section {
      margin: 8px;
      margin-bottom: 16px;
    }
  }
}

@media (min-width: 580px) {
  .recommendations {
    section {
      margin: 16px;
      border-radius: 50px 0 50px 0;
      padding: 24px 32px 24px 32px;
      margin-bottom: 32px;
    }
  }
}

@media (min-width: 768px) {
  .recommendations {
    section {
      margin-left: 16px;
      margin-bottom: 32px;
      margin-right: 16px;
      max-width: 1080px;
    }
  }
}

@media (min-width: 1200px) {
  .recommendations {
    section {
      margin-left: 10%;
      section {
        margin-left: 0;
      }
    }
  }
}
