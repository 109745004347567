body {
  .enjoy_hint_label {
    background-color: #fff;
    color: $mainTextColor;
    padding: 16px;
    text-align: left;

    string {
      color: $primaryColor;
    }

    &.enjoy_hint_label_welcome {
      font-size: 1rem;
      line-height: 20px;

      p {
        margin-bottom: 16px;
      }

      .checkbox label {
        padding-left: 0;

        label {
          padding-left: 20px;
        }
      }
    }

    p {
      font-size: 1rem;
      line-height: 20px;
      margin-bottom: 0;
    }
  }
  .enjoy_hint_primary_button,
  .enjoy_hint_secondary_button {
    border-color: $lsaviBlue;
    color: #fff;
    background-color: $lsaviBlue;

    &:hover,
    &:active {
      background-color: $lsaviBlue;
      border-color: $lsaviBlue;
    }
  }
  .enjoy_hint_secondary_button {
    background-color: transparent;
    color: $lsaviBlue;
    border-color: $lsaviBlue;
  }
  .enjoyhint_close_btn {
    border-color: $lsaviBlue;
    &:hover,
    &:active {
      background-color: $lsaviBlue;
      border-color: $lsaviBlue;
    }
  }

  section {
    .tour-button {
      display: inline-block;
      cursor: pointer;
      text-align: right;
      position: absolute;
      right: 16px;
      top: 32px;
      font-size: 0.9rem;
      line-height: 1rem;
      color: #999;
      span {
        display: none;
      }
      i {
        vertical-align: middle;
        color: #999;
        line-height: 1rem;
      }
      &:hover {
        text-decoration: none;
        color: $primaryColor;

        i {
          color: $primaryColor;
        }
      }
    }
  }
}

@media (min-width: 480px) {
  body {
    section {
      .tour-button {
        span {
          display: inline-block;
        }
      }
    }
  }
}

@media (min-width: 720px) {
  body {
    section {
      .tour-button {
        right: 32px;
        top: 50px;
      }
    }
  }
}
