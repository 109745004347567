#progress {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 4px;
}

.home-layout {
  #progress {
    top: 0;
  }
  &.body--scrolled {
    #progress {
      top: 76px;
    }
  }
}

.mdl-progress {
  width: 100%;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;

  &.mdl-progress__indeterminate {
    > .bar1 {
      background-color: $primaryColor;
    }
  }

  > .bufferbar {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
      linear-gradient(to right, $primaryColor, $primaryColor);
  }

  &.mdl-progress__indeterminate {
    > .bar3 {
      display: none;
    }
  }
}
