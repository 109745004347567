.dashboard {
  section.verifiers-admin {
    ol {
      margin: 16px 0;
      list-style: decimal;

      li {
        list-style: decimal;
        margin-left: 16px;
      }
    }

    textarea {
      height: 400px;
    }

    button {
      padding: 16px;
      margin: 8px 0;
    }

    .alert.alert--error,
    .alert.alert--highlight {
      padding-top: 16px;
      font-size: 1.1rem;
      margin: 16px 0;
    }

    .alert.alert--highlight strong {
      color: #000;
    }

    .buttons {
      display: flex;
      flex-direction: row;
      &.buttons-pair {
        button.verifiers-admin-cancel {
          margin-right: 8px;
          background-color: #999;
        }
      }
    }

    .well {
      margin: 32px 16px;
      padding: 16px;
      border: solid 1px #ccc;
      border-radius: 5px;
      font-family: 'Roboto', 'Franklin Gothic Medium', Tahoma, sans-serif;

      h5 {
        margin: 0 0 8px 0;
        font-weight: bold;
        color: #dd1c1a;
      }

      p {
        margin: 16px 0 8px 0;
        font-family: 'Roboto', 'Franklin Gothic Medium', Tahoma, sans-serif;
      }

      select {
        margin-bottom: 8px;
      }

      li {
        font-weight: bold;
      }
    }
  }
}

