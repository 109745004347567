.accreditation {
  section {
    background-color: #fff;
    padding: 8px;
    box-shadow: $boxShadow;

    h3 {
      margin: 0 0 16px 0;
      font-family: $robotoCondensedBoldFontStack;
      font-size: 2.4rem;
      line-height: 2.8rem;
      color: $lsaviRed;

      button {
        background-color: transparent;
        margin: 0;
        font-family: $robotoFontStack;
        text-transform: none;
        border: none;
        font-size: 0.9rem;
        line-height: 2.8rem;
        color: #666;
        display: inline-block;
        letter-spacing: normal;
        height: 2.8rem;
        text-decoration: underline;
        padding: 0 8px;

        &:hover {
          background-color: transparent;
          color: #000;
        }
      }
    }

    // p {
    //     font-family: $robotoCondensedFontStack;
    //     font-size: 1.2rem;
    //     line-height: 1.8rem;
    //     margin: 0 0 16px 0;

    //     .dashboard-score {
    //         color: $primaryColor;
    //         font-size: 1.8rem;
    //         line-height: 1.8rem;
    //     }
    // }
  }

  .form-group__label {
    padding: 0 0 8px 0;
    flex: 1 1 0;
  }
}

// @media (max-width: 360px) {

//     .dashboard {

//         section {

//             li {

//                 button {
//                     min-width: 280px;
//                     font-size: 1rem;
//                 }

//             }

//         }

//     }

// }

@media (min-width: 440px) {
  .accreditation {
    section {
      margin: 8px;
      margin-bottom: 16px;
    }
  }
}

@media (min-width: 580px) {
  .accreditation {
    section {
      margin: 16px;
      border-radius: 50px 0 50px 0;
      padding: 24px 32px 24px 32px;
      margin-bottom: 32px;
    }
  }
}

@media (min-width: 768px) {
  .accreditation {
    section {
      margin-left: 16px;
      margin-bottom: 32px;
      margin-right: 16px;
      max-width: 820px;
    }
  }
}

@media (min-width: 890px) {
  .accreditation {
    section {
      margin-left: 10%;
    }
  }
}

.accreditation {
  .textbox {
    margin-top: 16px;

    ul {
      margin: 0 0 16px 16px;

      &.mdl-list {
        margin: 16px 0 0 0;
      }
    }
  }

  .comments-form {
    .form-group {
      .formly-field {
        padding-top: 0;
        padding-left: 0;
        padding-right: 0;
        .formly-builder-step {
          margin: 0;
        }
      }
    }
    .form__main-buttons {
      margin-top: 16px;
    }
  }

  .form-group {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: flex-start;

    &.form-group--item {
      border: solid 1px #cbd2d0;
      padding: 16px;
      margin: 0 0 16px 0;
    }

    .form-group__label {
      padding: 0 0 8px 0;
      //flex: 0 0 180px;
      //white-space: nowrap;
    }
    .form-group__input {
      padding: 0 0 8px 0;
    }
    .form-group__buttons {
      padding: 0 0 8px 0;

      button.btn-disabled {
        opacity: 0.4;
        cursor: not-allowed;
      }

      button.auditor-form-submit {
        background-color: $lsaviRed;
        color: #fff;

        &[disabled],
        &.mdl-button--disabled.mdl-button--disabled {
          background-color: $lsaviRed;
          color: #fff;
          opacity: 0.4;
        }
      }
    }
  }

  .form__main-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .responsive-table {
    input,
    textarea {
      width: 100%;
    }
    .responsive-table__row {
      background-color: #f7f7f9;
      border: solid 1px #cbd2d0;
      margin-bottom: 8px;
      padding: 8px;
      &.responsive-table__head {
        border: none;
        background-color: transparent;
        margin: 0;
      }
      //border: solid 1px $
    }
  }

  .evidence-buttons {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    //align-items: center;
    button {
      margin: 0 0 8px 0;
    }
  }

  h4 {
    font-size: 1.6rem;
  }
}

@media (max-width: 619px) {
  .responsive-table {
    .responsive-table__row {
      .responsive-table__row-inner {
        .responsive-table__row-inner_bottom {
          text-align: right;
        }
      }
    }
  }
}

@media (min-width: 480px) {
  .accreditation {
    .comments-form {
      .form-group {
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;

        .formly-field {
          padding-left: 0;
          padding-right: 0;
          .formly-builder-step {
            margin: 0 0 16px;
          }
        }
      }
    }
    .form-group {
      flex-direction: row;
      align-items: center;

      &.form-group--row {
        flex-direction: column;
        align-items: flex-start;
      }

      &.form-group--item {
        border: solid 1px #cbd2d0;
        padding: 16px;
        margin: 0 0 16px 0;
      }

      .form-group__label {
        padding: 0 8px 0;
        min-width: 100px;
      }
      .form-group__input {
        padding: 0 8px 0;
        width: 100%;
        // input {
        //   min-width: 240px;
        // }
      }
      .form-group__buttons {
        padding: 0;
      }
    }
    .responsive-table {
      .form-group {
        flex-direction: column;
        align-items: flex-start;
        .form-group__input {
          padding: 0 0 8px;
          input {
            min-width: auto;
          }
        }
      }
    }
  }
}

.accreditation {
  .responsive-table {
    .responsive-table__row {
      .responsive-table__row-inner {
        div.pending-file-status {
          color: $dangerColor;
          &.pending-file-status-active {
            color: $warningColor;
          }
          &.pending-file-status-complete {
            color: $successColor;
          }
        }
      }
    }
  }
}

@media (min-width: 620px) {
  .accreditation {
    .responsive-table {
      .responsive-table__row {
        &.responsive-table__head {
          display: flex;
          .responsive-table__col {
            text-align: center;
            padding-left: 4px !important;
            padding-right: 4px !important;
          }
        }
        .responsive-table__col {
          flex-basis: 14%;
          text-align: right;
          padding-left: 4px !important;
          padding-right: 4px !important;
          &:first-child {
            flex-basis: 58%;
            text-align: left;
            padding-left: 0;
          }
        }
        .responsive-table__row {
        }
        .responsive-table__row-inner {
          &.responsive-table__row-inner__hide {
            display: none;
          }
          flex-direction: row;
          > div {
            width: auto;
          }
          .formly-field {
            flex-basis: 50%;
            //align-self: center;
            &:first-child {
              .form-group__input {
                padding: 0 8px 8px 0;
              }
            }
          }
          .form-group__input {
            //width: auto;
            padding: 0 0 8px;
          }
        }
      }
    }
    .evidence-buttons {
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      button {
        margin: 0 0 8px 8px;
      }
    }
  }
}

