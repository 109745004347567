$defaultModalSize: 280;
$defaultModalSizeWithMargin: $defaultModalSize + 16;

$mediumModalSize: 440;
$mediumModalSizeWithMargin: $mediumModalSize + 16;
$largeModalSize: 840;
$largeModalSizeWithMargin: $largeModalSize + 16;

$fullMinWidth: 740;

.tillr-mdl-modal {
  .tillr-mdl-modal__content {
    position: fixed;
    left: 8px;
    top: 8px;
    z-index: 1000;
    background-color: #fff;
    width: calc(100% - 16px);

    .mdl-dialog__title {
      padding: 16px;
    }

    .mdl-dialog {
      width: 100%;
      box-shadow: none;

      h3 {
        color: $lsaviRed;
        font-family: $robotoCondensedBoldFontStack;
        font-size: 1.8rem;
        line-height: 2rem;
        margin: 0 0 8px 0;
      }

      h4 {
        color: $lsaviBlue;
        font-family: $robotoCondensedBoldFontStack;
        font-size: 1.4rem;
        line-height: 1.8rem;
        margin: 8px 0 8px 0;
      }

      p {
        font-size: 1rem;
        line-height: 1.4rem;
        margin: 0 0 8px 0;

        strong {
          color: #000;
        }

        &.hero {
          font-family: $robotoCondensedFontStack;
          color: #000;
          font-size: 1.2rem;
          line-height: 1.6rem;
        }
      }

      .required-fields-info p {
        font-size: 1rem;
        line-height: 1.4rem;
      }

      .mdl-dialog__content {
        padding: 0 16px 16px 16px;

        p {
          font-size: 1rem;
          line-height: 1.4rem;
        }
      }

      .mdl-dialog__actions {
        button {
          color: $primaryColor;
          &[disabled],
          &[disabled='disabled'],
          &.mdl-button--colored-minor {
            color: #999;
          }
        }
      }
    }

    .mdl-spinner {
      display: block;
      margin: 16px auto;

      &.mdl-spinner--single-color {
        .mdl-spinner__layer-1 {
          border-color: $primaryColor;
        }
        .mdl-spinner__layer-2 {
          border-color: $lsaviRed;
        }
        .mdl-spinner__layer-3 {
          border-color: $lsaviBlue;
        }
        .mdl-spinner__layer-4 {
          border-color: $lsaviYellow;
        }
      }
    }
  }

  .tillr-mdl-modal__backdrop {
    position: fixed;
    left: 0%;
    top: 0%;
    width: 100%;
    height: 100%;
    z-index: 999;
    background: rgba(0, 0, 0, 0.7);
  }

  &.tillr-mdl-modal--full {
    z-index: 5;
    width: 100%;
    height: 100%;
    overflow: scroll;
    min-width: #{$fullMinWidth}px;

    .tillr-mdl-modal__content {
      background-color: $colorOffWhite;
      top: 8px;
      width: calc(100% - 16px);
      min-width: calc(#{$fullMinWidth}px - 16px);
      left: 8px;
      margin-bottom: 16px;

      overflow: scroll;

      .mdl-dialog {
        width: auto;
        //min-width: $fullMinWidth;
        min-height: calc(100vh - 8px);
        overflow: scroll;

        .mdl-dialog__title {
          //padding: 22px 0 12px;
          margin: 0 16px 0;
          border-bottom: solid 1px $hrLineColor;

          span {
            display: block;
            max-width: 70%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .mdl-dialog__actions {
          padding: 8px 16px;
        }

        .mdl-dialog__content {
          padding: 16px;
        }
      }
    }

    .tillr-mdl-modal__backdrop {
      background: rgba(0, 0, 0, 1);
    }
  }
}

@media (min-width: 580px) {
  .tillr-mdl-modal {
    .tillr-mdl-modal__content {
      top: 16px;
      width: calc(100% - 16px);

      .mdl-dialog__title {
        padding: 32px 32px 0 32px;
      }

      .mdl-dialog {
        h3 {
          font-size: 2.2rem;
          line-height: 2.6rem;
          margin: 0 0 16px 0;
        }

        h4 {
          font-size: 1.6rem;
          line-height: 2rem;
          margin: 0 0 16px 0;
        }

        p {
          font-size: 1.2rem;
          line-height: 1.6rem;

          &.hero {
            font-size: 1.6rem;
            line-height: 2rem;
          }
        }

        .required-fields-info p {
          font-size: 1rem;
          line-height: 1.4rem;
        }

        .mdl-dialog__content {
          padding: 0 32px 32px 32px;
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .tillr-mdl-modal {
    &.tillr-mdl-modal--full {
      .tillr-mdl-modal__content {
        .mdl-dialog {
          .mdl-dialog__title {
            span {
              max-width: 60%;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-height: 680px) {
  .tillr-mdl-modal {
    .tillr-mdl-modal__content {
      top: 5%;
    }
  }
}

@media screen and (min-width: #{$defaultModalSizeWithMargin}px) {
  .tillr-mdl-modal {
    .tillr-mdl-modal__content {
      left: calc((100% - #{$defaultModalSize}px) / 2);
      top: 20%;
      width: #{$defaultModalSize}px;

      .mdl-dialog {
        width: #{$defaultModalSize}px;
      }
    }

    &.tillr-mdl-modal--large {
      .tillr-mdl-modal__content {
        top: 10%;
        width: calc(100% - 16px);
        left: 8px;
        min-height: 200px;

        .mdl-dialog {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (min-width: #{$mediumModalSizeWithMargin}px) {
  .tillr-mdl-modal {
    &.tillr-mdl-modal--medium {
      .tillr-mdl-modal__content {
        left: calc((100% - #{$mediumModalSize}px) / 2);
        top: 20%;
        width: #{$mediumModalSize}px;

        .mdl-dialog {
          width: #{$mediumModalSize}px;
        }
      }

      &.tillr-mdl-modal--signature {
        .tillr-mdl-modal__content {
          left: calc((100% - #{$mediumModalSize}px) / 2);
          top: 20%;
          width: #{$mediumModalSize}px;
        }
      }
    }

    &.tillr-mdl-modal--large {
      .tillr-mdl-modal__content {
        top: 16px;
        width: calc(100% - 16px);
        left: 8px;

        .mdl-dialog {
          width: 100%;
        }
      }
    }

    &.tillr-mdl-modal--signature {
      .tillr-mdl-modal__content {
        .mdl-dialog__content {
          padding: 20px 24px 24px;
        }
      }
    }
  }
}

@media screen and (min-width: #{$largeModalSizeWithMargin}px) {
  .tillr-mdl-modal {
    &.tillr-mdl-modal--large {
      .tillr-mdl-modal__content {
        left: calc((100% - #{$largeModalSize}px) / 2);
        top: 16px;
        width: #{$largeModalSize}px;

        .mdl-dialog {
          width: #{$largeModalSize}px;

          .mdl-dialog__content {
            //overflow-y: scroll;
            //max-height: calc(100vh - 132px);
          }
        }
      }
    }
  }
}

