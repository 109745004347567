.print-friendly {
  display: none;
}

@media print {
  .recommendations {
    section {
      .go-to-link {
        display: none;
      }
    }
  }

  .hide-print {
    display: none;
  }

  .text.print-friendly {
    display: inline-block;
  }

  .advice-and-recommendations {
    h1 {
      a {
        img {
          width: 180px;
        }
      }
    }

    h3 {
      display: none;
    }

    h3.print-friendly {
      display: block;
    }

    div.collapsable-groups__group {
      display: none;
    }

    div.collapsable-groups__group.print-friendly-temp {
      display: block;
      .collapsable-groups__head-title {
        padding: 0 !important;
        margin-bottom: 16px;
        h3 {
          display: block;

          > .stars {
            display: none;
          }
          .text {
            min-width: 0 !important;
            &.print-friendly {
              display: inline-block !important;
            }
          }
        }
      }
      .collapsable-groups__head-toggle {
        display: none;
      }
      .collapsable-groups__body {
        display: block;
        border: none;
        box-shadow: none;
        padding: 0;

        hr {
          border-color: #000;
        }

        .recommendation {
          max-height: none;
          padding: 0;
          overflow: auto;
        }
        .read-more,
        .read-less {
          display: none;
        }
      }
    }

    section {
      padding: 0;
      margin: 16px;

      h3 {
        button {
          display: none;
        }
      }
    }
  }

  footer {
    > div {
      display: none;
    }
    p {
      display: none;
    }
    p.print-friendly {
      display: block;
    }
  }

  .mdl-layout__container {
    display: block !important;
  }

  @page {
    margin: 1cm 1cm 1cm 1cm;
    size: A4 portrait;
  }

  body.home-layout main.mdl-layout__content,
  main.mdl-layout__content {
    margin-top: 0 !important;
  }

  #snackbar {
    display: none;
  }

  aside {
    display: none;
  }

  p,
  input,
  select,
  label {
    page-break-inside: avoid;
  }

  select::-ms-expand {
    display: none;
  }

  .form-control[readonly] {
    color: #666;
    background-color: #fff;
    border-radius: 0;
    border: 1px solid #ccc;
  }

  .radio input[type='radio'],
  .radio-inline input[type='radio'],
  .checkbox input[type='checkbox'],
  .checkbox-inline input[type='checkbox'] {
    margin-left: 0;
  }

  .checkbox input[type='checkbox'],
  .checkbox-inline input[type='checkbox'] {
    top: 5px;
  }

  .radio input[type='radio'],
  .radio-inline input[type='radio'] {
    top: 3px;
  }

  input.form-control,
  select.form-control {
    // appearance not support by auto-prefixer
    // -webkit-appearance: none;
    // -moz-appearance: none;
    // appearance: none;

    border: 1px solid #ccc;
    box-shadow: none;
    transition: none;
  }

  // .form-group {
  //     page-break-before: always;
  //     break-before: always;
  // }

  // .form-control, div.checkbox {
  //     page-break-before: always;
  //     break-before: always;
  // }

  // .formly-field {
  //     page-break-before: always;
  //     break-before: always;
  //     padding-bottom: 2pt;
  // }

  .form-group .form-control,
  .form-group .form-control.friendly-readonly[disabled] {
    border: 1px solid #ccc;
  }

  .print-textarea {
    display: block;
    overflow: visible;
    white-space: pre-line;

    min-height: 100px;
    padding: 0 12px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #666;
    background-color: #fff;

    border: 1px solid #ccc;
  }

  .well {
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-bottom: 0;
  }

  body {
    &.home-layout {
      .mdl-layout__header {
        .mdl-layout__header-row {
          h6 {
            display: block;
          }
        }
      }
    }
  }

  .ribbon,
  body .ribbon,
  body.home-layout.body--scrolled .ribbon,
  body.body--scrolled .ribbon {
    display: none !important;
  }

  .progress,
  body .progress,
  body.home-layout.body--scrolled .progress,
  body.body--scrolled .progress {
    display: none !important;
  }

  body.home-layout {
    .mdl-layout__header {
      display: block;
      h1 {
        top: 8px;
      }
    }
  }

  body {
    .mdl-layout__header {
      display: block;
      h1 {
        left: 0 !important;
        top: 8px;
      }
    }
  }

  body {
    .mdl-layout__header {
      display: block;
      h6 {
        right: 0 !important;
      }
    }
  }

  body.home-layout {
    .mdl-layout__header {
      background-color: transparent;
    }
  }

  body.home-layout.body--scrolled {
    .mdl-layout__header {
      background-color: transparent;
    }
  }

  .paper .paper-clip + h2 {
    text-indent: 0;
  }

  .radio input[type='radio'] {
    margin-left: 0;
  }

  .checkbox input[type='checkbox'] {
    margin-left: 0;
  }

  .paper button.tillr-form-info {
    display: none;
  }

  .action-buttons {
    display: none;
  }

  section {
    padding: 0;
  }

  section.paper {
    padding: 0;
  }

  .mdl-layout__header,
  body.home-layout.body--scrolled .mdl-layout__header,
  body.body--scrolled .mdl-layout__header {
    border: none;
    position: static;
    left: 0;
    top: 0;
  }

  a[href]:after {
    content: none !important;
  }

  .home-layout,
  body.home-layout.body--scrolled {
    h1#main-logo {
      display: none;
    }
    h6 {
      display: block;
      z-index: 10000;
    }
    h6 a span {
      display: none;
    }
  }

  .mdl-layout__header {
    position: static;
    left: 0;
    top: 0;
  }

  .welcome {
    section {
      button {
        display: none;
      }
    }
  }

  footer {
    padding: 10px 0 0 0;
    .mdl-mega-footer--top-section {
      display: none;
    }
    .mdl-mega-footer--bottom-section {
      display: none;
    }
  }

  #contact-button {
    display: none;
  }

  div.assessment {
    section,
    section:not(.step) {
      max-width: 100%;
      width: 100%;
      margin: 0;
      padding: 16px;

      .row {
        margin: 0;
      }

      h2,
      h3 {
        font-family: $defaultFontStack;
      }

      .form-paper h2 {
        font-size: 1.8rem;
        line-height: 2.2rem;
        border-bottom: solid 1px #000;
      }

      h3 button,
      div.alert.alert--info.alert--info-small,
      div.form-paper-status,
      div.form-button-container,
      div.required-fields-info {
        display: none;
      }

      section.step {
        padding: 0;
      }

      div.formly-field {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }

      .formly-form.formly-form-collapsable-groups
        .formly-form-collapsable-groups__group
        .formly-form-collapsable-groups__head {
        display: none;
      }

      .formly-form.formly-form-collapsable-groups
        .formly-form-collapsable-groups__group
        .formly-form-collapsable-groups__body {
        display: block;
        border: none;
        padding: 0;

        h2 {
          font-size: 1.6rem;
          line-height: 3rem;
          border-bottom: solid 1px #000;
        }
      }
    }
  }
}

