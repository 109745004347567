.form-paper {
  width: 100%;
  background-color: #f9f9f9;
  box-shadow: $mdl-box-shadow;
  display: flex;
  position: relative;

  form {
    width: 100%;
  }

  section {
    padding-top: 0;
    padding-bottom: 0;
  }

  .form-builder--wizard > h2 {
    display: none;
  }

  fieldset {
    padding-top: 0;
    margin-top: 0;
  }

  .progress-indicator {
    margin: 16px 0 0 0;
  }

  .form-paper-status {
    h4 {
      display: none;
    }

    background-color: $lsaviBlue;
    padding: 16px;
    box-shadow: $mdl-box-shadow;
    //margin: 0 -32px;

    button {
      margin-top: 16px;
      font-size: 0.9rem;
    }

    &.form-paper-status--bottom {
      a {
        display: none;
      }
      button {
        margin-top: 0;
      }
    }
  }

  section {
    background-color: transparent;
  }

  h2 {
    font-size: 2.2rem;
    font-family: $robotoFontStack;
    border-bottom: solid 2px $lsaviBlue;
    padding-bottom: 16px;
    line-height: 2.6rem;
    color: $lsaviBlue;
    font-weight: bold;
  }

  .formly-form-collapsable-groups__group h2 {
    font-size: 1.8rem;
    font-family: $robotoFontStack;
    border: none;
    padding-bottom: 0;
    line-height: 2.2rem;
    color: $mainTextColor;
    margin-top: 0;
  }
}

.form-group {
  margin-bottom: 0;

  &.ng-invalid.ng-pristine {
    input,
    select,
    textarea {
      border-color: #ccc;
    }

    label {
      color: $mainTextColor;
    }

    .glyphicon {
      display: none;
    }
  }

  &.ng-invalid.ng-dirty {
    input,
    select,
    textarea {
      border-color: $dangerColor;
    }

    label {
      color: $dangerColor;
    }
  }

  &.ng-valid {
    &.edit {
      input,
      select,
      textarea {
        border-color: $successColor;

        &.no-change {
          border-color: #ccc;
        }
      }
    }
  }
}

.checkbox > label,
.radio > label {
  padding-left: 0;
}

div.radio,
div.checkbox {
  margin-bottom: 18px;
}

.radio > label,
.checkbox > label {
  padding-left: 40px;
  text-indent: -35px;
}

span.required-field {
  color: red;
  font-weight: bold;
}

.required-fields-info {
  p {
    display: inline-block;
    margin: 0;
    padding: 0 0 6px;
    color: #666;
    font-size: 0.9rem;
    span {
      color: red;
      font-weight: bold;
    }
  }
}

.assessment-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 16px 0;
  flex-direction: column;

  p {
    margin: 8px 0;
  }
}

.form-button-container {
  background-color: $lsaviBlue;
  box-shadow: $boxShadow;
  padding: 8px;
  //border-top: solid 1px $lsaviPale;
  border-top: solid 1px #0b4358;

  .form-button-container--head {
    p {
      color: #999;
      margin: 0 0 8px;
    }
  }

  .form-button-container--body {
    display: flex;
    align-items: center;
    width: 100%;
    flex-grow: 1;
    justify-content: flex-end;

    .form-button-container--left {
      display: flex;
      flex-grow: 1;
      justify-content: flex-start;
    }

    .form-button-container--right {
      flex-direction: column;
      display: flex;
      //flex-grow: 1;
      justify-content: flex-end;
    }

    button {
      margin: 0 0 8px 8px;
      height: auto;
      max-width: 160px;
      padding: 8px;
      line-height: 16px;
      min-width: auto;
      color: #fff;

      &.mdl-button--raised {
        background-color: $primaryColor;
        color: #fff;

        &:disabled {
          opacity: 0.5;
          &:hover {
            background-color: $primaryColor;
          }
        }

        &:hover {
          background-color: $lsaviRed;
        }
      }
    }
  }
}

@media (min-width: 540px) {
  .form-button-container {
    .form-button-container--body {
      .form-button-container--right {
        flex-direction: row;
        flex-grow: 1;
      }

      button {
        margin-bottom: 0;
      }
    }
  }
}

// .form-button-container {
//     display: flex;
//     justify-content: flex-end;
//     margin: 12px 0 0 0;
//     background-color: $primaryColor;
//     box-shadow: $boxShadow;
//     padding: 8px;

//     &.form-button-container--bottom {
//         margin-bottom: 16px;
//     }

//     button {
//         color: #fff;
//         margin-left: 8px;
//     }

//     .tlr-btn-save {
//         background-color: $lsaviYellow;
//         color: $mainTextColor;
//     }

//     .tlr-btn-submit {
//         background-color: #65d074;
//     }
// }

.form-control.friendly-readonly[readonly] {
  background-color: #fff;
  cursor: default;
}

.form-control.friendly-readonly[disabled] {
  background-color: $colorOffWhite;
  border: 0;
  box-shadow: none;
  cursor: default;
}

.formly-field.col-xs-8 {
  padding-left: 0;
  padding-right: 0;
}

button.tillr-form-info {
  background-color: transparent;
  border: none;
  outline: none;
  color: $primaryColor;
  i:hover {
    color: $mainTextColor;
  }
}

@media not print {
  .radio-inline {
    top: -1px;
  }

  .radio-inline .magic-radio + label,
  .checkbox-inline .magic-checkbox + label {
    display: inline-block;
    top: -7px;
    padding-left: 24px;
  }

  .magic-radio + label,
  .magic-checkbox + label {
    display: inline-block;
    top: -1px;
  }

  .magic-checkbox:checked + label:before {
    border-color: $primaryColor;
    background-color: $primaryColor;
  }

  .magic-radio + label:before,
  .magic-radio:checked + label:before {
    border-color: $primaryColor;
  }

  .magic-radio + label:after {
    background-color: $primaryColor;
  }

  @keyframes hover-color {
    from {
      border-color: #c0c0c0;
    }
    to {
      border-color: #999;
    }
  }

  .magic-radio:checked[disabled] + label:before {
    border: 1px solid #c7e7e8;
  }

  .magic-radio:checked[disabled] + label:after {
    //background: #c7e7e8;
  }

  .magic-checkbox:checked[disabled] + label:before {
    border: #c7e7e8;
    background: #c7e7e8;
  }
}

/* form group wrapper for collapsable groupd sections */
.formly-form {
  .formly-form-collapsable-groups__head {
    display: none;
  }
}

.formly-form {
  &.formly-form-collapsable-groups {
    .formly-form-collapsable-groups__group {
      clear: both;
      margin: 0 0 16px 0;
      .formly-form-collapsable-groups__head {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-content: flex-start;
        background-color: #e6e6e6;
        align-items: center;
        color: $mainTextColor;

        button {
          border: none;
          background-color: $lsaviBlue;
          color: #fff;
          &:hover {
            text-decoration: underline;
          }
        }

        &.formly-form-collapsable-groups__head--begun {
          background-color: #edb10a;
        }

        &.formly-form-collapsable-groups__head--complete {
          background-color: #65d074;
          color: #fff;

          h3 {
            color: #fff;
          }
        }

        .formly-form-collapsable-groups__head-title {
          flex-basis: 70%;
          padding: 8px;
        }
        .formly-form-collapsable-groups__head-status {
          flex-basis: 20%;
          padding: 8px;
          text-align: center;
        }
        .formly-form-collapsable-groups__head-toggle {
          flex-basis: 10%;
          padding: 8px;
          text-align: right;
        }

        p {
          margin: 0;
          font-size: 1rem;
          line-height: 1.4rem;
          font-weight: bold;
          font-family: $defaultFontStack;
        }

        h3 {
          margin: 0;
          font-size: 1rem;
          line-height: 1.4rem;
          font-family: $defaultFontStack;
          color: $mainTextColor;
          font-weight: bold;
        }
      }
      // .formly-form-collapsable-groups__head {
      //     display: block;
      //     background-color: #e6e6e6;
      //     position: relative;
      //     button {
      //         padding: 16px 64px 16px 16px;
      //         font-size: 1.2rem;
      //         background-color: transparent;
      //         display: block;
      //         //width: 100%;
      //         text-align: left;
      //         border: none;

      // overflow: hidden;
      // display: block;
      // white-space: nowrap;
      // text-overflow: ellipsis;

      //         i {
      //             position: absolute;
      //             right: 16px;
      //             top: 20px;
      //         }
      //     }
      // }
      .formly-form-collapsable-groups__body {
        border-style: solid;
        border-color: #e6e6e6;
        border-width: 0 1px 1px 1px;
        padding: 16px;
        display: none;
      }
      &.formly-form-collapsable-groups__group--visible {
        .formly-form-collapsable-groups__body {
          display: block;
        }
      }
    }
  }
}

.fb-custom-warn,
.fb-custom-danger,
.fb-custom-info,
.fb-custom-success {
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  padding: 8px 35px 8px 14px;
  margin: 0 0 16px 0;
}

.fb-custom-warn {
  color: #c09853 !important;
  background-color: #fcf8e3 !important;
  border-color: #c09853 !important;
}
.fb-custom-danger {
  color: #b94a48 !important;
  background-color: #f2dedf !important;
  border-color: #b94a48 !important;
}
.fb-custom-info {
  color: #3a87ad !important;
  background-color: #daedf7 !important;
  border-color: #3a87ad !important;
}
.fb-custom-success {
  color: #468847 !important;
  background-color: #dff0d8 !important;
  border-color: #468847 !important;
}

.triage {
  hr {
    margin-top: 16px;
  }
  .formly-field {
    margin-bottom: 16px;
    &.formly-field-group {
      margin-bottom: 0;
    }
  }
  ul {
    margin-left: 16px;
  }
}

