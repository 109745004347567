.home-page {
  button {
    display: inline-block;
    margin: 0 auto 8px 0;
    font-family: 'Roboto Condensed', sans-serif;
    text-transform: uppercase;
    background-color: $lsaviRed;
    height: auto;
    padding: 32px;
    border-radius: 5px;
    letter-spacing: 1px;
    line-height: 1.6rem;
    color: #fff;
    font-weight: normal;
    border: none;
    font-size: 1.6rem;

    &:hover {
      background-color: $lsaviBlue;
      color: $lsaviYellow;
    }

    &:last-child {
      font-size: 1rem;
      background-color: transparent;
      padding: 8px 32px;
      color: #111;
      text-decoration: underline;
      margin-bottom: 0;
    }
  }

  .home-page__title {
    position: relative;
    padding-top: 0;

    h2 {
      font-family: $robotoCondensedBoldFontStack;
      font-size: 2.4rem;
      line-height: 2.8rem;
      color: #fff;
      text-transform: uppercase;
      background-color: rgba(0, 0, 0, 0.7);
      padding: 8px;
      margin: 0;

      span {
        display: block;
        font-size: 1.2rem;
        line-height: 1.8rem;
        text-transform: none;
        color: #fff1d0;

        strong {
          color: #00a0e4;
        }
      }
    }

    p {
      color: #fff;
      background-color: $primaryColor;
      font-family: $robotoCondensedFontStack;
      font-size: 1.4rem;
      line-height: 1.8rem;
      margin: 0;
    }

    div.home-page__buttons:last-of-type {
      display: none;
    }
  }

  .home-page__video {
    padding: 0;
    video {
      width: 100%;
    }
  }

  .home-page__more {
    display: none;
  }

  .home-page__buttons {
    text-align: center;
    padding: 16px 0 8px 0;
  }

  section {
    padding-top: 16px;

    h3 {
      font-size: 2rem;
      line-height: 2.4rem;
      margin: 0 8px 8px 8px;
      font-family: $robotoCondensedBoldFontStack;
      position: relative;
      padding-left: 56px;

      i {
        position: absolute;
        top: 6px;
        left: 8px;
      }
    }

    p {
      &.intro {
        font-family: $robotoCondensedFontStack;
        font-size: 1.4rem;
        line-height: 1.8rem;
        font-weight: bold;
      }

      font-size: 1rem;
      line-height: 1.6rem;
      padding: 8px;
      margin: 0;

      strong {
        font-weight: bold;
      }
    }

    li {
      i {
        position: relative;
        top: 3px;
      }
      list-style: none;
      margin: 0 8px 8px 8px;
    }

    img {
      margin: 0 auto 16px auto;
      width: calc(100% - 16px);
      max-width: 280px;
      display: block;
    }

    &.home-content__guidance {
      background-color: #fff;
      h3 {
        color: $lsaviRed;
      }
      button:first-of-type {
        background-color: $lsaviBlue;
        &:hover {
          background-color: $lsaviYellow;
          color: $lsaviRed;
        }
      }
    }

    &.home-content__how {
      color: #fff;
      background-color: $lsaviBlue;
      h3 {
        color: $lsaviYellow;
      }
      button {
        &:first-of-type {
          background-color: $primaryColor;
          &:hover {
            background-color: $lsaviYellow;
            color: $lsaviRed;
          }
        }
        &:last-child {
          color: #fff;
        }
      }
    }

    &.home-content__award {
      background-color: $lsaviYellow;
      h3 {
        color: $lsaviBlue;
      }
    }
  }
}

@media (min-width: 375px) {
  .home-page {
    .home-page__title {
      h2 {
        font-size: 2.8rem;
        line-height: 3.4rem;

        span {
          font-size: 1.5rem;
          line-height: 2rem;
        }
      }
    }
  }
}

@media (min-width: 420px) {
  .home-page {
    .home-page__title {
      margin: 0;

      h2 {
        padding: 8px 16px;
        margin: 0 8px;
      }

      p {
        padding: 8px;
        margin: 0 8px 8px 8px;
        border-radius: 0 0 50px 0;
      }
    }

    .home-page__video {
      border-radius: 50px 0 0 0;
      overflow: hidden;
      margin: 0 8px;
    }

    .home-page__buttons {
      margin: 0 8px;
    }

    section {
      margin: 0 8px 8px 8px;
    }
  }
}

@media (min-width: 460px) {
  .home-page {
    .home-page__title {
      h2 {
        font-size: 3.2rem;
        line-height: 3.8rem;
        padding: 16px;

        span {
          font-size: 2rem;
          line-height: 2.4rem;
        }
      }
    }

    section p {
      padding: 0 16px;
      margin: 0 0 8px;
    }
  }
}

@media (min-width: 580px) {
  .home-page {
    .home-page__title {
      margin: 0;

      h2 {
        font-size: 3.6rem;
        line-height: 4rem;
        padding: 16px 32px;
        margin: 0 16px;

        span {
          font-size: 2.4rem;
          line-height: 2.8rem;
        }
      }

      p {
        margin: 0 16px 16px 16px;
        padding: 16px 32px;
      }
    }

    .home-page__video {
      margin: 0 16px;
    }

    section {
      margin: 0 16px 16px;

      p {
        margin: 0 0 16px;
      }

      li {
        margin: 0 16px 8px 16px;
      }
    }
  }
}

@media (min-width: 768px) {
  .home-page {
    .home-page__buttons {
      text-align: left;
      margin: 0;
      padding: 0;
    }

    section {
      margin: 0;

      &.home-page__video {
        margin: 0;
        position: relative;
        z-index: 2;
        border-radius: 50px 0 50px 0;
        overflow: hidden;
        position: relative;
        z-index: 1;
        max-height: 585px;
        video {
          height: 100%;
          max-height: 635px;
          width: auto;
        }
      }

      &.home-page__title {
        position: relative;
        z-index: 3;

        h2 {
          padding: 16px 32px;
          border-radius: 65px 0 0 0;
          margin: 0 0 8px 0;
          font-size: 4rem;
          line-height: 4.6rem;

          span {
            font-size: 2.6rem;
            line-height: 2.8rem;
          }
        }

        p {
          font-size: 1.6rem;
          line-height: 2.2rem;
          padding: 16px;
          margin: 0 0 8px 0;
          border-radius: 0 0 65px 0;
        }

        div.home-page__buttons {
          display: none;
        }

        div.home-page__buttons:last-of-type {
          display: block;
        }
      }

      &.home-content {
        z-index: 3;
        position: relative;
        border-radius: 50px 0 50px 0;

        div {
          padding: 24px 32px 24px 32px;
          overflow: hidden;

          &.home-page__buttons {
            padding: 0;
          }

          h3 {
            font-size: 2.6rem;
            line-height: 3.2rem;
          }

          p {
            padding: 0;
            font-size: 1.2rem;
            line-height: 2rem;
            &.intro {
              font-size: 1.6rem;
              line-height: 2.4rem;
            }
          }

          ul {
            li {
              margin: 0 0 12px 0;
              font-size: 1.2rem;
              line-height: 2rem;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .home-page {
    section {
      &.home-page__video {
        max-height: 635px;
        video {
          max-height: 685px;
        }
      }
    }
  }
}

// .grid {
//     width: 100vw;

//     // display: -ms-grid;
//     // display: grid;
//     // -ms-grid-columns: (1fr)[8];
//     // grid-template-columns: repeat(8, 1fr);
//     // -ms-grid-rows: (5em)[20];
//     // grid-template-rows: repeat(20, 5em);

//     button {
//         display: inline-block;
//         margin: 0 auto;
//         font-family: 'Roboto Condensed', sans-serif;
//         text-transform: uppercase;
//         background-color: $lsaviRed;
//         height: auto;
//         padding: 32px;
//         border-radius: 5px;
//         letter-spacing: 1px;
//         line-height: 1.6rem;
//         color: #fff;
//         font-weight: normal;
//         margin: 0 8px 0 0;
//         border: none;
//         font-size: 1.6rem;

//         &:hover {
//             background-color: $lsaviBlue;
//             color: $lsaviYellow;
//         }

//         &:last-child {
//             font-size: 1rem;
//             background-color: transparent;
//             padding: 8px 32px;
//             color: #111;
//             text-decoration: underline;
//         }
//     }

//     .dashboard-content {
//         z-index: 3;
//         position: relative;
//         grid-column: 2/span 5;
//         grid-row: 2/span 4;

//         div {
//             padding: 24px 32px 24px 32px;
//             border-radius: 50px 0 50px 0;
//             background-color: #fff;

//             h3 {
//                 font-size: 2.6rem;
//                 line-height: 3.2rem;
//                 margin: 16px 0 16px 0;
//                 font-family: $robotoCondensedBoldFontStack;

//                 i {
//                     margin-right: 8px;
//                 }
//             }

//             p {
//                 font-size: 1.2rem;
//                 line-height: 2rem;
//                 &.intro {
//                     font-family: $robotoCondensedFontStack;
//                     font-size: 1.6rem;
//                     line-height: 2.4rem;
//                     font-weight: bold;
//                 }
//                 &:last-child {
//                     margin-bottom: 0;
//                 }
//             }

//             ul {
//                 margin: 0;
//                 padding: 0;
//                 list-style: none;

//                 li {
//                     margin: 0 0 12px 0;
//                     font-size: 1.2rem;
//                     line-height: 2rem;

//                     i {
//                         position: relative;
//                         top: 4px;
//                     }
//                 }
//             }

//         }
//     }

// }

// .home-layout {

//     #page-2,
//     #page-3,
//     #page-4 {
//         display: none;
//     }

//     .grid {
//         //display: none;

//         width: 100vw;
//         height: 100vh;

//         display: -ms-grid;
//         display: grid;
//         -ms-grid-columns: (1fr)[8];
//         grid-template-columns: repeat(8, 1fr);
//         -ms-grid-rows: (1fr)[6];
//         grid-template-rows: repeat(6, 1fr);

//         .header {
//             position: relative;
//             grid-column: 1/span 8;
//             grid-row: 1;
//         }

//         .grid__item__cosmetic {
//             //display: none;
//             position: relative;
//             z-index: -1;
//             border-radius: 50px 0 50px 0;
//             &.grid__item--shade {
//                 background-color: #eae2cf;
//                 border-radius: 0;
//             }

//             &.grid__item__cosmetic-image {
//                 z-index: 0;
//                 div {
//                     position: relative;
//                     overflow: hidden;
//                     width: 100%;
//                     height: 100%;
//                     border-radius: 50px 0 50px 0;
//                     img {
//                         position: absolute;
//                         top: -60px;
//                         left: 0;
//                     }
//                 }
//             }
//         }

//         .grid__item__cosmetic--alpha {
//             grid-column: 8;
//             grid-row: 6;
//         }

//         .grid__item__cosmetic--bravo {
//             grid-column: 6;
//             grid-row: 6;
//         }

//         .grid__item__cosmetic--charlie {
//             grid-column: 1/span 2;
//             grid-row: 3;
//         }

//         .grid__item__cosmetic--delta {
//             grid-column: 1/span 5;
//             grid-row: 2/span 4;
//         }

//         .grid__item__cosmetic--echo {
//             grid-column: 7;
//             grid-row: 1/span 2;
//         }

//         .grid__item__cosmetic--foxtrot {
//             grid-column: 1;
//             grid-row: 6;
//         }

//         .grid__item__cosmetic--golf {
//             grid-column: 7/span 2;
//             grid-row: 6;
//         }

//         .grid__item__cosmetic--hotel {
//             grid-column: 4/span 5;
//             grid-row: 2/span 4;
//         }

//         .grid__item__cosmetic--indigo {
//             grid-column: 8;
//             grid-row: 1;
//         }

//         .grid__item__cosmetic--juliet {
//             grid-column: 2/span 3;
//             grid-row: 6;
//         }

//         .grid__item__cosmetic--kenya {
//             grid-column: 7;
//             grid-row: 6;
//         }

//         .grid__item__cosmetic--lima {
//             grid-column: 1/span 5;
//             grid-row: 1/span 5;
//         }

//         .grid__item__cosmetic--mike {
//             grid-column: 6/span 3;
//             grid-row: 4;
//         }

//         .grid__item__cosmetic--november {
//             grid-column: 6;
//             grid-row: 1;
//         }

//         .grid__item__cosmetic--oscar {
//             grid-column: 8;
//             grid-row: 6;
//         }

// button {
//     display: inline-block;
//     margin: 0 auto;
//     font-family: 'Roboto Condensed', sans-serif;
//     text-transform: uppercase;
//     background-color: $lsaviRed;
//     height: auto;
//     padding: 32px;
//     border-radius: 5px;
//     letter-spacing: 1px;
//     line-height: 1.6rem;
//     color: #fff;
//     font-weight: normal;
//     margin: 0 8px 0 0;
//     border: none;
//     font-size: 1.6rem;

//     &:hover {
//         background-color: $lsaviBlue;
//         color: $lsaviYellow;
//     }

//     &:last-child {
//         font-size: 1rem;
//         background-color: transparent;
//         padding: 8px 32px;
//         color: #111;
//         text-decoration: underline;
//     }
// }

//         .home-title {
//             position: relative;
//             grid-column: 2/span 4;
//             grid-row: 2/span 4;
//             z-index: 3;

//             h2 {
//                 font-family: $robotoCondensedBoldFontStack;
//                 font-size: 4rem;
//                 line-height: 4.6rem;
//                 color: #fff;
//                 border: none;
//                 font-weight: bold;
//                 text-transform: uppercase;
//                 background-color: rgba(0, 0, 0, .7);
//                 padding: 16px 32px;
//                 border-radius: 65px 0 0 0;
//                 margin: 0 0 8px 0;

//                 span {
//                     display: block;
//                     font-size: 2.6rem;
//                     line-height: 2.8rem;
//                     text-transform: none;
//                     color: #fff1d0;

//                     strong {
//                         color: #00a0e4;
//                     }
//                 }
//             }

//             p.hero {
//                 color: #fff;
//                 font-family: $robotoCondensedFontStack;
//                 font-size: 1.6rem;
//                 line-height: 2.2rem;
//                 background-color: $primaryColor;
//                 padding: 16px;
//                 margin: 0 0 8px 0;
//                 border-radius: 0 0 65px 0;

//                 strong {
//                     font-weight: bold;
//                 }
//             }

//         }

//         .home-video {
//             position: relative;
//             grid-column: 5/span 4;
//             grid-row: 1/span 5;
//             z-index: 2;
//             border-radius: 50px 0 50px 0;
//             overflow: hidden;
//             position: relative;
//             z-index: 1;
//             video {
//                 height: 100%;
//             }
//         }

//         .home-more {
//             position: relative;
//             grid-column: 4/span 2;
//             grid-row: 6;
//             z-index: 2;

//             a {
//                 font-family: $robotoCondensedFontStack;
//                 font-size: 1.4rem;
//                 position: absolute;
//                 left: 0;
//                 bottom: 16px;
//                 display: block;
//                 text-align: center;
//                 width: 100%;
//                 color: #111;
//             }

//         }

//         .home-content {
//             z-index: 3;
//             position: relative;

//             div {
//                 padding: 24px 32px 24px 32px;
//                 border-radius: 50px 0 50px 0;

//                 h3 {
//                     font-size: 2.6rem;
//                     line-height: 3.2rem;
//                     margin: 16px 0 16px 0;
//                     font-family: $robotoCondensedBoldFontStack;

//                     i {
//                         margin-right: 8px;
//                     }
//                 }

//                 p {
//                     font-size: 1.2rem;
//                     line-height: 2rem;
//                     &.intro {
//                         font-family: $robotoCondensedFontStack;
//                         font-size: 1.6rem;
//                         line-height: 2.4rem;
//                         font-weight: bold;
//                     }
//                     &:last-child {
//                         margin-bottom: 0;
//                     }
//                 }

//                 ul {
//                     margin: 0;
//                     padding: 0;
//                     list-style: none;

//                     li {
//                         margin: 0 0 12px 0;
//                         font-size: 1.2rem;
//                         line-height: 2rem;

//                         i {
//                             position: relative;
//                             top: 4px;
//                         }
//                     }
//                 }

//             }

//             &.home-content__guidance {
//                 grid-column: 3/span 5;
//                 grid-row: 2/span 4;

//                 div {
//                     background-color: #fff;

//                     h3 {
//                         color: $lsaviRed;
//                     }

//                     p {
//                         color: #333;
//                         &.intro {
//                             color: #000;
//                         }
//                     }

//                     button:first-of-type {
//                         background-color: $lsaviBlue;
//                         &:hover {
//                             background-color: $lsaviYellow;
//                             color: $lsaviRed;
//                         }
//                     }
//                 }
//             }

//             &.home-content__how {
//                 grid-column: 1/span 6;
//                 grid-row: 2/span 5;

//                 div {
//                     background-color: $lsaviBlue;

//                     h3 {
//                         color: $lsaviYellow;
//                     }

//                     p {
//                         color: #fff;
//                         &.intro {
//                             color: #fff;
//                         }
//                     }

//                     ul {
//                         li {
//                             color: #fff;
//                             i {
//                                 color: $primaryColor;
//                             }
//                         }
//                     }

//                     button:first-of-type {
//                         background-color: $primaryColor;
//                         &:hover {
//                             background-color: $lsaviYellow;
//                             color: $lsaviRed;
//                         }
//                     }
//                 }
//             }

//             &.home-content__award {
//                 grid-column: 2/span 5;
//                 grid-row: 2/span 4;

//                 div {
//                     background-color: $lsaviYellow;

//                     h3 {
//                         color: $lsaviBlue;
//                     }

//                     div {
//                         padding: 0;
//                         background-color: transparent;
//                         width: 70%;
//                         max-width: 360px;
//                         margin: 0 auto 16px auto;
//                     }

//                     p {
//                         color: #333;
//                         &.intro {
//                             color: #000;
//                         }
//                     }
//                 }
//             }
//         }

//     }

// }

// .home-layout .grid.grid-full {
//     position: absolute;
//     top: 0;
//     left: 0;

//     width: 100vw;
//     height: 100%;

//     display: -ms-grid;
//     display: grid;
//     -ms-grid-columns: (1fr)[8];
//     grid-template-columns: repeat(8, 1fr);
//     -ms-grid-rows: (8em)[20];
//     grid-template-rows: repeat(20, 8em);

//     .grid-cosmetic-block {
//         position: relative;
//         z-index: -1;
//         border-radius: 50px 0 50px 0;
//         background-color: transparent;

//         &.grid-cosmetic-block--shade {
//             background-color: #eae2cf;
//             border-radius: 0;
//         }

//         div {
//             position: relative;
//             overflow: hidden;
//             width: 100%;
//             height: 100%;
//             border-radius: 50px 0 50px 0;
//             img {
//                 position: absolute;
//                 top: 0;
//                 left: 0;
//                 width: 100%;
//             }
//         }
//     }
// }

