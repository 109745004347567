.tillr-mdl-modal.tillr-mdl-modal--large.document-modal {
  .tillr-mdl-modal__content {
    top: 8px;
    position: absolute;
    margin-bottom: 8px;

    .mdl-dialog {
      h3 {
        color: $lsaviBlue;
      }

      ol.lower-alpha {
        list-style: lower-alpha;
      }

      ol li {
        margin-bottom: 16px;
      }

      h4 {
        color: $lsaviBlue;
        margin: 16px 0;
      }

      p {
        margin: 0 0 16px;

        strong {
          font-weight: bold;
          color: $lsaviBlue;
        }

        a {
          //color: $lsaviBlue;

          &:hover {
            color: #000;
          }
        }

        &.intro {
          color: #000;
        }

        a.as-title {
          font-weight: bold;
          color: $lsaviBlue;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
            color: #000;
          }
        }
      }

      ul {
        margin: 0 0 16px;
      }

      li {
        margin-left: 16px;
        font-size: 1rem !important;
        line-height: 1.4rem !important;
        margin-bottom: 8px;
      }

      .confirmation {
        padding: 8px;
        border-radius: 4px;
        background-color: $lsaviGreyBlue;
        color: $mainTextColor;
        margin: 8px;

        &.confirmation--disabled {
          .formly-field {
            opacity: 0.5;
          }
        }

        div.checkbox {
          margin: 0;
        }

        // .checkbox label {
        // 	font-weight: bold;
        // 	//padding-left: 0;
        // 	label {
        // 		padding-left: 30px;
        // 	}
        // }

        .checkbox > label {
          font-weight: bold;
          padding-left: 40px;
          text-indent: -35px;
        }

        .magic-radio + label:before,
        .magic-checkbox + label:before {
          background-color: #fff;
        }
        .magic-checkbox:checked + label:before {
          border-color: #00a0e4;
          background-color: #00a0e4;
        }

        .buttons {
          text-align: right;

          button:disabled {
            background-color: #ccc;
            color: #999;
          }

          button {
            margin-top: 8px;
            background-color: rgba(0, 160, 228, 1);
            color: #fff;
            display: inline-block;
          }
        }
      }
    }

    .buttons {
      text-align: right;
      margin: 8px;

      button {
        margin-top: 8px;
        background-color: rgba(0, 160, 228, 1);
        color: #fff;
        display: inline-block;
      }
    }
  }

  .tillr-mdl-modal__backdrop {
    position: fixed;
    left: 0%;
    top: 0%;
    width: 100%;
    height: 100%;
    z-index: 999;
    background: rgba(0, 0, 0, 0.9);
  }
}

@media (min-width: 540px) {
  .tillr-mdl-modal.tillr-mdl-modal--large.document-modal {
    .tillr-mdl-modal__content {
      top: 16px;
      margin-bottom: 16px;

      .mdl-dialog {
        p,
        ul {
          margin: 0 0 32px 0;
        }

        ul ul {
          margin-bottom: 0;
        }

        li {
          margin-left: 16px;
          font-size: 1.2rem;
          line-height: 1.6rem;
        }

        .confirmation {
          padding: 16px;
          border-radius: 4px;
          margin: 16px;

          label {
            font-size: 1.2rem;
            line-height: 1.6rem;
          }
        }

        .buttons {
          margin: 16px;
        }
      }
    }
  }
}

