.registration-was-modal {
  padding-top: 0;

  .tillr-mdl-modal {
    .mdl-dialog__content {
      //padding: 32px;
    }

    .tillr-mdl-modal__content {
      padding-top: 16px;
      position: static;
      background-color: #fff;
      width: 100%;
      max-width: 840px;
      margin: 0 auto;
      box-shadow: $boxShadow;

      .mdl-dialog {
        width: 100%;
        min-height: 300px;

        .mdl-spinner {
          margin: 32px auto !important;
        }
      }
    }
  }

  .registration-modal__form {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    padding: 0 !important;

    h3.mdl-dialog__title {
      padding: 0;
      font-size: 2.6rem;
      line-height: 3rem;
    }

    p {
      margin-bottom: 24px !important;

      &.registration-modal__p--no-margin {
        margin-bottom: 0 !important;
        strong {
          font-weight: bold;
        }
      }
      &.registration-modal__p--sm-margin {
        margin-bottom: 14px !important;
      }
    }

    section {
      //margin: 8px 0;

      .form-group {
        margin-bottom: 16px;
        //width: 100%;
      }

      .form-control {
        //width: 100%;
      }

      .already-registered {
        background-color: #086788;
        padding: 16px;
        margin: 0 0 8px 0;
        width: 100%;

        h4 {
          color: #fff;
          margin: 0 0 16px;
        }
      }

      hr {
        margin: 16px 0;
      }

      button {
        width: 100%;
      }

      button.mdl-button:first-of-type {
        background-color: $lsaviRed;
        color: #fff;

        &[disabled],
        &[disabled='disabled'] {
          opacity: 0.6;
        }
      }
      button.mdl-button:last-of-type {
        margin-top: 8px;
      }

      .already-registered {
        button.mdl-button {
          background-color: #00a0e4;
        }
      }

      &:first-child {
        border-bottom: solid 1px #ccc;
        //padding-bottom: 16px;
        //margin-bottom: 8px;
      }

      &:last-child {
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;

        hr {
          display: block;
          width: 100%;
        }

        p {
          width: 100%;
          display: block;
          margin: 0 !important;
        }

        .lsavi-image {
          display: flex;
          flex-direction: column;
          align-items: center;
          align-content: center;

          padding: 24px 0;
          //flex-direction: column;
        }

        img {
          //margin: 0 auto;
          width: 90%;
          max-width: 300px;
          //display: block;
          //width: 100px;
          //height: 100px;
        }

        // button {
        //     display: inline-block;
        //     margin: 0 auto;
        //     font-family: 'Roboto Condensed', sans-serif;
        //     text-transform: uppercase;
        //     background-color: $primaryColor;
        //     height: auto;
        //     padding: 16px;
        //     border-radius: 5px;
        //     letter-spacing: 1px;
        //     line-height: 1.6rem;
        //     color: #fff;
        //     font-weight: normal;
        //     margin: 0 8px 0 0;
        //     border: none;
        //     font-size: 1.2rem;

        //     &:hover {
        //         background-color: $lsaviBlue;
        //         color: $lsaviYellow;
        //     }
        // }
      }
    }

    div.form-group {
      margin-bottom: 24px;
    }

    div.checkbox {
      margin-bottom: 32px;
    }

    label {
      color: #000;
    }
  }
}

@media (min-width: 680px) {
  .registration-was-modal {
    .registration-modal__form {
      flex-direction: row;

      section {
        flex-basis: 40%;
        padding-left: 16px;
        //margin: 16px 0;

        &:first-child {
          flex-basis: 60%;
          padding-right: 32px;
          padding-left: 0;
          border-right: solid 1px #ccc;
          border-bottom: 0;
        }

        &:last-child {
          hr:first-of-type {
            display: none;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 580px) {
  .registration-was-modal {
    .tillr-mdl-modal {
      .tillr-mdl-modal__content {
        top: auto;
        width: calc(100% - 32px) !important;
        margin-bottom: 16px !important;
        padding-top: 16px;
        //padding: 0 !important;
      }
    }

    .tillr-mdl-modal__content .mdl-dialog .mdl-dialog__content {
      padding: 16px 32px 32px !important;
    }
  }

  //.tillr-mdl-modal
}

@media screen and (min-height: 680px) {
  .registration-was-modal {
    .tillr-mdl-modal {
      .tillr-mdl-modal__content {
        position: static;
        top: auto;
      }
    }
  }
}

@media screen and (min-width: 420px) {
  .registration-was-modal {
    padding-top: 20px;

    .tillr-mdl-modal {
      .tillr-mdl-modal__content {
        border-radius: 5px;
        left: auto;
        top: auto;
        width: calc(100% - 16px);
        //margin-bottom: 8px;

        .mdl-dialog {
          width: 100%;
        }
      }

      &.tillr-mdl-modal--medium {
        .tillr-mdl-modal__content {
          top: auto;
          width: 100%;
          left: auto;
          min-height: none;

          .mdl-dialog {
            width: 100%;
          }
        }
      }
    }
  }
}

