/*////////////////// Fonts */
$defaultFontStack: 'Lato', 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue',
  Helvetica, Arial, 'Lucida Grande', sans-serif;
$robotoFontStack: 'Roboto', 'Franklin Gothic Medium', Tahoma, sans-serif;
$robotoCondensedFontStack: 'Roboto Condensed', Impact, 'Franklin Gothic Bold', sans-serif;
$robotoCondensedBoldFontStack: 'Roboto Condensed Bold', Impact, 'Franklin Gothic Bold', sans-serif;
/*////////////////// /Fonts */

/*////////////////// Font sizes */
$defaultFontSize: 1;
/*////////////////// /Font sizes */

/*////////////////// Colours */
$primaryColor: #00a0e4;
$tillrOrange: #fd7300;
$lsaviBlue: #086788;
$lsaviYellow: #f0c808;
$lsaviPale: #fff1d0;
$lsaviRed: #dd1c1a;
$lsaviGreyBlue: #e8eef1;
$lsaviPaleRed: #f9cdcd;
$lsaviLightGrey: #eeeeee;
$completeColor: #0fec0f;

$dangerColor: #a94442;
$successColor: #5cb85c;
$warningColor: #f0ad4e;
$infoColor: #5bc0de;

$mainTextColor: #111;
$colorOffWhite: #f3f3f3;

$noStars: #c6291b;
$oneStar: #b4492c;
$twoStars: #9d7141;
$threeStars: #869755;
$fourStars: #73b767;
$fiveStars: #66cf73;

$hrLineColor: #e6e6e6;

/*////////////////// Colours */

// $formElementBorderColor: #ccc;

// $footerBackground: #424242;

// $standardProgressForeground: $primaryColor;
// $standardProgressBackground: #ffcc99;

// $accountsProgressForeground: $secondaryColor;
// $accountsProgressBackground: #83c7ca;

// $colorOffWhite: #f3f3f3;
// $colorOffWhiteGrey: #dadada;
// $colorOffWhiteDarkGrey: #cacaca;

// $hrLineColor: #e6e6e6;
// $hrLineStyle: solid;

// $headerBackgroundColor: #232a34;
// $headerMainLinkColor: #fff;
// $headerMainLinkHoverColor: $secondaryColor;
// $headerMobileHeaderToggle: $headerMainLinkColor;

// $drawerBackgroundColor: #232a34;

// $crumbBackgroundColor: $secondaryColor;
// $crumbColor: #fff;
// $crumbLinkColor: $crumbColor;
// $crumbLinkHoverColor: #333;

// $btnOneColor: #fff;
// $btnOneHoverColor: #333;
// $btnOneBackgroundColor: $secondaryColor;

// $btnTwoColor: #fff;
// $btnTwoHoverColor: #cecece;
// $btnTwoBackgroundColor: #15172e;

// $btnThreeColor: #15172e;
// $btnThreeHoverColor: #666;
// $btnThreeBackgroundColor: #dadada;
// /*////////////////// /Colours */

/*////////////////// Shadows */
$headerBoxShadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
  0 1px 5px 0 rgba(0, 0, 0, 0.12);
$boxShadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
$mdl-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
  0 1px 5px 0 rgba(0, 0, 0, 0.12);
$mdl-box-shadow-reverse: 0 -2px 2px 0 rgba(0, 0, 0, 0.14), 0 -3px 1px -2px rgba(0, 0, 0, 0.2),
  0 -1px 5px 0 rgba(0, 0, 0, 0.12);
$mdl-box-shadow-2: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
  0 1px 5px 0 rgba(0, 0, 0, 0.12);
$mdl-box-shadow-3: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.2),
  0 1px 8px 0 rgba(0, 0, 0, 0.12);
$mdl-box-shadow-4: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12),
  0 2px 4px -1px rgba(0, 0, 0, 0.2);
$mdl-box-shadow-6: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12),
  0 3px 5px -1px rgba(0, 0, 0, 0.2);
$mdl-box-shadow-8: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12),
  0 5px 5px -3px rgba(0, 0, 0, 0.2);
$mdl-box-shadow-16: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12),
  0 8px 10px -5px rgba(0, 0, 0, 0.2);
$mdl-box-shadow-24: 0 9px 46px 8px rgba(0, 0, 0, 0.14), 0 11px 15px -7px rgba(0, 0, 0, 0.12),
  0 24px 38px 3px rgba(0, 0, 0, 0.2);
/*////////////////// /Shadows */

/*////////////////// Utils */
$maxZIndex: 2147483647;
/*////////////////// /Utils */

