.alert {
  background-color: $lsaviBlue;
  padding: 8px;
  color: #fff;
  margin: 0 0 8px;

  &.alert--resources,
  &.alert--docs,
  &.alert--reports,
  &.alert--forms {
    background-color: $lsaviGreyBlue;

    h4 {
      color: $lsaviBlue;
    }

    a {
      color: #000;
    }
  }

  &.alert--aside,
  &.alert--info {
    background-color: $lsaviGreyBlue;
    color: #000;
    padding-top: 8px;

    h4 {
      color: $lsaviBlue;
      font-size: 1.2rem;
      margin: 0 0 4px;
    }

    a {
      color: #000;
      font-size: 1rem;
      line-height: 1.4rem;
      padding-left: 20px;

      i {
        line-height: 1.4rem;
        top: 1px;
      }
    }

    &.alert--aside {
      background-color: $lsaviGreyBlue;
    }

    &.alert--info-small {
      padding: 8px !important;
      p {
        margin: 0;
        line-height: 1.4rem;
        font-size: 1rem;
        color: #333;

        &:first-child {
          margin: 0 0 8px;
        }

        &.align-right {
          text-align: right;

          a {
            text-decoration: underline;
            color: #000;
            padding: 0;
            line-height: 1.4rem;
            font-size: 1rem;

            &:hover {
              color: $lsaviRed;
            }
          }
        }
      }
    }
  }

  &.alert--docs {
    background-color: $lsaviYellow;
  }
  &.alert--forms {
    background-color: $lsaviPale;
  }

  &.alert--highlight {
    background-color: $lsaviYellow;
    color: $mainTextColor;
    padding-top: 8px;

    h4 {
      color: $primaryColor;
      font-size: 2rem;
      margin: 8px 0;
    }

    strong {
      text-transform: uppercase;
      color: $primaryColor;
      font-weight: bold;
    }

    a {
      color: $mainTextColor;
      text-decoration: underline !important;
      font-size: inherit;
      text-decoration: none;
      line-height: inherit;
      position: static;
      display: inline;
      padding: 0;
    }
  }

  &.alert--error {
    background-color: $lsaviPaleRed;
    color: $mainTextColor;
    padding-top: 8px;

    h4 {
      color: $mainTextColor;
      font-size: 2rem;
      margin: 8px 0;
      text-transform: uppercase;
    }

    h5 {
      font-family: $robotoCondensedBoldFontStack;
      color: $mainTextColor;
      font-size: 1.8rem;
      margin: 0 0 16px;
      letter-spacing: normal;
    }

    a {
      color: #fff;
      font-size: 1rem;
      line-height: 1.4rem;
      padding-left: 20px;

      i {
        line-height: 1.4rem;
        top: 1px;
      }
    }

    ul {
      li {
        button {
          background-color: $lsaviBlue;
          color: #fff;
          //font-weight: bold;
          &:hover {
            background-color: $lsaviRed;
            color: #fff;
          }
        }
      }
    }
  }

  h4 {
    margin: 0 0 8px 0;
    color: $lsaviYellow;
    font-weight: bold;
    font-family: $robotoCondensedBoldFontStack;
  }

  p {
    margin: 0 0 8px 0;
  }

  ul,
  li {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li {
    margin: 0 0 16px;
  }

  a {
    color: $lsaviPale;
    font-size: 1.2rem;
    text-decoration: none;
    line-height: 1.6rem;
    position: relative;
    display: block;
    padding-left: 24px;

    i {
      line-height: 1.6rem;
      position: absolute;
      top: 3px;
      left: 0;
    }

    i.glyphicon {
      top: 0;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  li.checklist {
    margin: 0 0 16px;
    position: relative;

    i {
      line-height: 1.6rem;
      position: absolute;
      top: -2px;
      left: 0;
      font-size: 1.6rem;

      &.glyphicon-unchecked {
        color: $lsaviRed;
      }
      &.glyphicon-check {
        color: #00b050;
      }
    }

    a {
      color: $lsaviYellow;
      font-size: 1.2rem;
      text-decoration: none;
      line-height: 1.6rem;
      position: relative;
      display: block;
      padding-left: 28px;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  li.strike {
    a {
      text-decoration: line-through;
    }
  }
}

@media (min-width: 540px) {
  .alert {
    padding: 16px;
    margin-bottom: 16px;
    border-radius: 4px;
  }
}

