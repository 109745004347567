.form-progress {
  //padding: 24px 0 0 0;
  //background-color: $rdPale;
  position: relative;

  > div {
    height: 32px;
  }

  h4 {
    display: none;
  }

  em {
    font-style: normal;
    font-weight: bold;
  }

  ul {
    margin: 4px 0 0 0;
  }

  strong {
    display: none;
    font-weight: normal;
  }

  .progress-indicator > li .bubble {
    border: none;
  }

  i.glyphicon {
    vertical-align: baseline;
    color: #64d074;
  }

  button {
    font-size: 0.8rem !important;
    margin-top: 0 !important;
    text-transform: uppercase;
    text-decoration: none;
    color: #999;
  }

  li.completed {
    button {
      color: #64d074;
    }
  }

  #form-progress-prev {
    position: absolute;
    left: 8px;
    bottom: auto;
  }

  #form-progress-next {
    position: absolute;
    right: 8px;
    bottom: auto;
  }
}

@media (min-width: 500px) {
  .form-progress {
    ul {
      margin: 0;
    }
  }
}

@media (min-width: 580px) {
  .form-progress {
    strong {
      display: inline-block;
    }
  }
}

