html {
  body {
    .content {
      main {
        padding-left: 0;
        padding-right: 0;
        margin: 0 auto;
        width: 100%;

        .banner {
          width: 100%;
          position: relative;
          z-index: 998;
          top: -1px;
          background: blue url('/app/images/bg-6.jpg') no-repeat center center;
          background-size: cover;
          display: flex;
          padding: 28px 0;
          align-items: center;

          div.banner__inner {
            width: 100%;
            background-color: rgba(255, 255, 255, 0.8);

            div.banner__title {
              margin: 0 auto;
              width: 100%;
              max-width: 1280px;

              h2 {
                font-size: 2.8em;
                font-weight: bold;
                margin: 0;
                padding: 4px 8px 18px 8px;
                line-height: 1em;
                font-family: $robotoCondensedBoldFontStack;
              }

              &.no--crumbs {
                h2 {
                  padding-top: 18px;
                }
              }
            }

            div.banner__breadcrumbs {
              padding-top: 18px;
              margin: 0 auto;
              width: 100%;
              max-width: 1280px;

              ul,
              li {
                margin: 0;
                padding: 0;
                list-style: none;
              }

              ul {
                display: flex;
                padding-left: 8px;
                flex-direction: column;

                li {
                  font-size: 1.2em;
                  font-family: $robotoCondensedFontStack;
                  padding-right: 8px;

                  a {
                    color: #096788;
                  }
                }
              }
            }
          }
        }

        .journey {
          padding-left: 0;
          padding-right: 0;

          > div {
            > section {
              .wrapper {
                margin-top: 20px;
              }
            }
          }
        }

        .assessment {
          padding-left: 0px;
          padding-right: 0px;
          width: 100%;
          max-width: 1280px;
          margin-left: auto;
          margin-right: auto;

          > section {
            margin-top: 0px;
            box-shadow: none;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            max-width: 1280px;
            padding-top: 32px;
          }
        }

        .recommendations.advice-and-recommendations {
          padding-left: 0px;
          padding-right: 0px;
          width: 100%;
          max-width: 1280px;
          margin-left: auto;
          margin-right: auto;

          > section {
            margin-top: 0px;
            box-shadow: none;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            max-width: 1280px;
            padding-top: 32px;
          }
        }

        .accreditation {
          padding-left: 0px;
          padding-right: 0px;
          width: 100%;
          max-width: 1280px;
          margin-left: auto;
          margin-right: auto;

          > section {
            margin-top: 0px;
            box-shadow: none;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            max-width: 1280px;
            padding-top: 32px;
          }
        }

        .dashboard {
          padding-left: 0px;
          padding-right: 0px;
          width: 100%;
          max-width: 1280px;
          margin-left: auto;
          margin-right: auto;

          > section {
            margin-top: 0px;
            box-shadow: none;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            max-width: 1280px;
            padding-top: 32px;
          }
        }
      }
    }
  }
}

@media (min-width: 580px) {
  html {
    body {
      .content {
        main {
          .banner {
            div.banner__inner {
              div.banner__title {
                padding-left: 20px;
                padding-right: 20px;
              }

              div.banner__breadcrumbs {
                padding-left: 20px;
                padding-right: 20px;
                ul {
                  flex-direction: row;
                }
              }
            }
          }

          .journey {
            padding-left: 20px;
            padding-right: 20px;
          }

          .assessment {
            margin-top: 20px;
            padding-left: 20px;
            padding-right: 20px;
          }

          .recommendations.advice-and-recommendations {
            margin-top: 20px;
            padding-left: 20px;
            padding-right: 20px;
          }

          .accreditation {
            margin-top: 20px;
            padding-left: 20px;
            padding-right: 20px;
          }

          .dashboard {
            margin-top: 20px;
            padding-left: 20px;
            padding-right: 20px;
          }

          .page {
            margin-top: 20px;
            padding-left: 20px;
            padding-right: 20px;
          }
        }
      }
    }
  }
}

@media (min-width: 780px) {
  html {
    body {
      .content {
        header {
          .signed-in-as {
            &__inner {
              width: 100%;
              max-width: 1280px;
              margin: 0 auto;
            }
          }

          .main {
            width: 100%;
            max-width: 1280px;
            margin: 0 auto;
          }
        }

        main {
          .banner {
            div.banner__inner {
              padding-left: 32px;
              padding-right: 32px;

              .banner__title {
                padding-left: 40px;
                padding-right: 40px;
              }
              .banner__breadcumbs {
                padding-left: 40px;
                padding-right: 40px;
              }
            }
          }

          .journey {
            padding-left: 40px;
            padding-right: 40px;
          }

          .assessment {
            padding-left: 40px;
            padding-right: 40px;
          }

          .recommendations.advice-and-recommendations {
            padding-left: 40px;
            padding-right: 40px;
          }

          .accreditation {
            padding-left: 40px;
            padding-right: 40px;
          }

          .dashboard {
            padding-left: 40px;
            padding-right: 40px;
          }

          .page {
            padding-left: 40px;
            padding-right: 40px;
          }
        }
      }
    }
  }
}

@media (min-width: 1280px) {
  html {
    body {
      .content {
        header {
          .main {
            padding-left: 0;
            padding-right: 0;
          }
        }

        main {
          .banner {
            .banner__title {
              padding-left: 30px;
              padding-right: 30px;
            }
            .banner__breadcrumbs {
              padding-left: 30px;
              padding-right: 30px;
            }
          }

          .journey {
            padding-left: 0px;
            padding-right: 0px;
          }

          .assessment {
            padding-left: 0px;
            padding-right: 0px;
          }

          .recommendations.advice-and-recommendations {
            padding-left: 0px;
            padding-right: 0px;
          }

          .accreditation {
            padding-left: 0px;
            padding-right: 0px;
          }

          .dashboard {
            padding-left: 0px;
            padding-right: 0px;
          }

          .page {
            padding-left: 0px;
            padding-right: 0px;
          }
        }
      }
    }
  }
}

